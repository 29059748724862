import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './routing/PrivateRoute';

import './App.css';

import SignInSide from './pages/authz/SignInSide';
import SignUp from './pages/authz/SignUp';
import SignUpComplete from './pages/onboarding/SignUpComplete';
import Onboarding from './pages/onboarding/Onboarding';
import Balance from './pages/balance/Balance';
import MainLayout from './layout/MainLayout/MainLayout';
import Dashboard from './pages/dashboard/Dashboard';
import Learning from './pages/learning/Learning';
import Lessons from './pages/lessons/Lessons';
import Profile from './pages/profile/Profile';
import Games from './pages/games/Games';
import EmailAction from './pages/authz/EmailAction';
import NotFoundPage from './pages/404/NotFoundPage';

function App() {
  return (
    <>
      <Routes>
        <Route path="signin" element={<SignInSide />} />
        <Route path="signup" element={<SignUp />} />
        <Route
          path="email-action"
          element={<EmailAction />}
        />
        <Route
          path="signup-complete"
          element={<PrivateRoute> <SignUpComplete /> </PrivateRoute>}
        />
        <Route
          path="onboarding"
          element={<PrivateRoute> <Onboarding /> </PrivateRoute>
          }
        />
        <Route path="games/:gameId" element={<PrivateRoute><Games /></PrivateRoute>} />
        <Route path="lessons/:lessonId?" element={<PrivateRoute><Lessons /></PrivateRoute>} />
        <Route
          path="/"
          exact
          element={<PrivateRoute> <MainLayout /> </PrivateRoute>}
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="learning/:journeyId?" element={<Learning />} />
          <Route path="balance" element={<Balance />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
