import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Grid, Paper, Typography, Box } from '@mui/material';

import {
  fetchUserTransactions,
  fetchUserEarnings,
  fetchUserFlows,
  sendSats,
  fetchInvoiceForUser,
  cleanInvoice,
  fetchInvoiceStatus,
  fetchRunningBalance,
  fetchUser,
} from '../../redux/slices/user';
import { fetchBtcMarketData } from '../../redux/slices/market-data';
import { fetchTopEarners } from '../../redux/slices/leaderboard';

import DashboardContainer from '../../ui-components/Common/Container/DashboardContainer';
import BalanceTransactionsItem from '../../ui-components/Balance/Paper/BalanceTransactionsItem';
import BitcoinBalance from '../../ui-components/Balance/Paper/BitcoinBalance';
import MarketData from '../../ui-components/Balance/Paper/MarketData';
import EarningsChart from '../../ui-components/Balance/Charts/EarningsChart';
import FlowsChart from '../../ui-components/Balance/Charts/FlowsChart';
import TransactionsChips from '../../ui-components/Balance/Chip/TransactionsChips';
import DashboardPaper from '../../ui-components/Common/Paper/DashboardPaper';
import SendSatsModal from '../../ui-components/Balance/Modal/SendSatsModal';
import LeaderboardTable from '../../ui-components/Balance/Tables/LeaderboardTable';
import TopUpModal from '../../ui-components/Balance/Modal/TopUpModal';

const Balance = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();


  const user = useSelector((state) => state.user.userFromFirebase);
  const lineItems = useSelector((state) => state.user.lineItems);
  const satsbalance = useSelector((state) => state.user.satsbalance);
  const earnings = useSelector((state) => state.user.earnings);
  const flows = useSelector((state) => state.user.flows);
  const invoice = useSelector((state) => state.user.invoice);
  const invoicePaid = useSelector((state) => state.user.invoicePaid);
  const runningBalance = useSelector((state) => state.user.runningBalance);
  const { btcusd, percent_change_7d, percent_change_24h, percent_change_90d, volume_24h } = useSelector((state) => state.marketData.btc);
  const marketStatus = useSelector((state) => state.marketData.status);
  const { topEarners } = useSelector((state) => state.leaderboard);

  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [topupModalOpen, setTopupModalOpen] = useState(false);
  const [sendSatsTo, setSendSatsTo] = useState(null);

  useEffect(() => {
    if (user) {
      dispatch(fetchUserTransactions({ uuid: user.uid, createdAtFrom: dayjs().subtract(7, 'days').toISOString() }));
      dispatch(fetchUserEarnings(user.uid));
      dispatch(fetchUserFlows(user.uid));
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(fetchBtcMarketData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTopEarners());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.uid) {
      dispatch(fetchRunningBalance(user.uid));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (invoicePaid) {
      setTopupModalOpen(false);
      dispatch(cleanInvoice());
      dispatch(fetchUserTransactions({ uuid: user.uid, createdAtFrom: dayjs().subtract(7, 'days').toISOString(), }));
      return;
    }
    const interval = setInterval(() => {
      if (invoice) {
        dispatch(fetchInvoiceStatus(invoice.id));
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [invoice, invoicePaid]);

  const handleTopupAmountSelection = (amount) => {
    dispatch(fetchInvoiceForUser({ uuid: user.uid, amount }));
  };

  const handleTransactionDateRangeChange = (chip) => {
    const { lessDays } = chip;
    const createdAtFrom = dayjs().subtract(lessDays, 'days').toISOString();
    dispatch(fetchUserTransactions({ uuid: user.uid, createdAtFrom }));
  };

  const handleSend = ({ receiver, amount, actionType, invoice }) => {
    dispatch(
      sendSats({
        action: actionType,
        sender: user.uid,
        receiver: receiver,
        invoice,
        amount: Number.parseInt(amount),
      })
    )
      .unwrap()
      .then(() => enqueueSnackbar(`Sucessfully sent to ${receiver || invoice.substring(0, 10) + '...'}`, { variant: 'success' }))
      .catch((err) => enqueueSnackbar('Failed to send. Please try again later.', { variant: 'error' }))
      .then(() => setSendModalOpen(false))
      .then(() => dispatch(fetchUser(user.uid)));
  };

  const handleLeaderboardSend = (user) => {
    const { uuid } = user;
    setSendSatsTo(uuid);
    setSendModalOpen(true);
  };

  return (
    <>
      <DashboardContainer alignItems={'center'} justifyContent={'center'}>
        <Grid container item xs={12} sx={{ height: { lg: '100vh', md: '100%' } }} spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ height: '300px' }}>
            <DashboardPaper bgColor={'#080404'} bgImage={'linear-gradient(147deg, #080404 0%, #5854ec 46%, #44d0d8 100%)'}>
              <BitcoinBalance
                balance={satsbalance}
                btcusd={btcusd}
                runningBalance={runningBalance}
                onSend={() => setSendModalOpen(true)}
                onTopUp={() => setTopupModalOpen(true)}
              />
            </DashboardPaper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} sx={{ height: '300px' }}>
            <DashboardPaper>
              <MarketData
                btcusd={btcusd}
                percent_change_7d={percent_change_7d}
                percent_change_24h={percent_change_24h}
                percent_change_90d={percent_change_90d}
                volume_24h={volume_24h}
                status={marketStatus}
              />
            </DashboardPaper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} sx={{ height: '300px' }}>
            <DashboardPaper>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography variant="dashboard">{t('weekly_earnings')}</Typography>
                <EarningsChart earnings={earnings} />
              </Box>
            </DashboardPaper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} sx={{ height: '300px' }}>
            <DashboardPaper>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography variant="dashboard">
                  {t('account_in_out_flows')}
                </Typography>
                <FlowsChart flows={flows} />
              </Box>
            </DashboardPaper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              height: {
                lg: 'calc(100vh - 350px)',
                md: '450px',
                sm: '450px',
                xs: '400px',
              },
            }}
          >
            <DashboardPaper>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography variant="dashboard">{t('transactions')}</Typography>
                <TransactionsChips
                  defaultChip={1}
                  onChipClicked={handleTransactionDateRangeChange}
                />
                <Paper
                  sx={{ overflow: 'auto', boxShadow: 'none', height: '100%' }}
                >
                  {lineItems?.map((li, index) => (
                    <BalanceTransactionsItem
                      key={index}
                      entryType={li.entryType}
                      type={li.type}
                      amount={li.amount}
                      meta={li.meta}
                      createdAt={li.createdAt}
                    />
                  ))}
                </Paper>
              </Box>
            </DashboardPaper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              height: {
                lg: 'calc(100vh - 350px)',
                md: '450px',
                sm: '450px',
                xs: '400px',
              },
            }}
          >
            <DashboardPaper>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography variant="dashboard">{t('leaderboard')}</Typography>
                <Paper
                  sx={{ overflow: 'auto', boxShadow: 'none', height: '100%' }}
                >
                  <LeaderboardTable
                    topEarners={topEarners}
                    onSend={handleLeaderboardSend}
                  />
                </Paper>
              </Box>
            </DashboardPaper>
          </Grid>
        </Grid>
      </DashboardContainer>
      <TopUpModal
        open={topupModalOpen}
        qrValue={invoice?.request}
        onClose={() => { setTopupModalOpen(false); dispatch(cleanInvoice()) }}
        onAmountSelected={handleTopupAmountSelection}
      />
      <SendSatsModal
        open={sendModalOpen}
        onClose={() => { setSendModalOpen(false); setSendSatsTo(null); }}
        onSend={handleSend}
        maxSatsAllowed={satsbalance}
        sendToUuid={sendSatsTo}
      />
    </>
  );
};

export default Balance;
