import React from 'react';
import { useParams } from 'react-router-dom';

import GamesWrapper from '../../ui-components/Games/Container/GamesWrapper';
import RussianRoulette from '../../ui-components/Games/Functionality/RussianRoulette';
import Blackjack from '../../ui-components/Games/Functionality/Blackjack';
import ChineseMahjong from '../../ui-components/Games/Functionality/ChineseMahjong';

const Games = ({ }) => {
    const { gameId } = useParams();

    let gameContent = null;
    if (gameId === 'russian-roulette') {
        gameContent = <RussianRoulette />;
    }
    if (gameId === 'blackjack') {
        gameContent = <Blackjack />;
    }

    if (gameId === 'chinese-mahjong') {
        gameContent = <ChineseMahjong />
    }

    return (
        <GamesWrapper>
            {gameContent}
        </GamesWrapper>
    );
};

export default Games;