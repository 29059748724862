import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Button, Typography, Grid, Box, Paper } from '@mui/material';

import { playBlackjack } from '../../../redux/slices/games';
import { fetchUser } from '../../../redux/slices/user';

import GameCard from './GameCard';
import BackToDashboardButton from '../../Common/Buttons/BackToDashboardButton';


const Blackjack = () => {
    const [isDrawDisabled, setIsDrawDisabled] = useState(false);
    const [areHitStandDisabled, setAreHitStandDisabled] = useState(true);
    const [isGameOver, setIsGameOver] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const uid = useSelector((state) => state.user.userFromFirebase?.uid);
    const userFromDb = useSelector((state) => state.user.user);
    const { house, user, result, payout } = useSelector((state) => state.games.games.blackjack);

    const fullName = userFromDb ? `${userFromDb.firstName} ${userFromDb.lastName}` : 'Anonymous';


    useEffect(() => {
        if (!userFromDb && uid) {
            dispatch(fetchUser(uid));
        }
    }, []);

    useEffect(() => {
        if (result && result === 'HOUSE_WON') {
            setIsGameOver(true);

        }

        if (result && result === 'USER_WON') {
            setIsGameOver(true);
        }
    }, [result]);

    const handleDraw = () => {
        dispatch(playBlackjack('DRAW'));
        setIsDrawDisabled(true);
        setAreHitStandDisabled(false);
    };

    const handleHit = () => {
        dispatch(playBlackjack('HIT'));
    };

    const handleStand = () => {
        dispatch(playBlackjack('STAND'));
    };

    const handleNavigateToDashboard = () => {
        navigate('/dashboard');
    };


    return (
        <>
            <BackToDashboardButton onClick={handleNavigateToDashboard} />
            <Stack direction="column" sx={{ height: '100%', width: '100%' }}>
                <Grid container>
                    <Grid item sm={6} md={6} lg={6} sx={{ justifyContent: 'center', display: 'flex', height: { lg: '200px', md: '120px', sm: '140px' } }}>
                        <Stack direction="column" alignItems="center" gap={3}>
                            <Typography sx={{ fontSize: '1.3rem', color: 'white' }}>{t('house_of_satspanda')}</Typography>
                            {isGameOver && (
                                <Box sx={{
                                    width: '140px', height: '42px', border: `3px solid ${result === 'HOUSE_WON' ? '#5CEF9F' : '#FF5A82'}`,
                                    borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                }}>
                                    <Typography sx={{ color: 'white' }}>{result === 'HOUSE_WON' ? 'Won' : 'Lost'}</Typography>
                                </Box>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item sm={6} md={6} lg={6} sx={{ justifyContent: 'center', display: 'flex' }}>
                        <Stack direction="column" alignItems="center" gap={3}>
                            <Typography sx={{ fontSize: '1.3rem', fontWeight: '500', color: 'white' }}>{fullName}</Typography>
                            {isGameOver && (
                                <Box sx={{
                                    width: '140px', height: '42px', border: `3px solid ${result === 'USER_WON' ? '#5CEF9F' : '#FF5A82'}`,
                                    borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                }}>
                                    <Typography sx={{ color: 'white' }}>{result === 'USER_WON' ? `Won ${payout}` : `Lost ${payout}`}</Typography>
                                </Box>
                            )}
                        </Stack>
                    </Grid>

                    <Grid item sm={6} md={6} lg={6} sx={{ justifyContent: 'center', display: 'flex', height: { lg: '300px', md: '280px', sm: '340px' } }}>
                        <Stack sx={{ position: 'relative', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                            {house?.map((c, index) => (
                                <Paper sx={{ p: '4px', borderRadius: '12px', height: '230px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    style={{
                                        position: 'absolute',
                                        left: `${index * 60}px`,
                                        zIndex: house.length + index,
                                    }}
                                    key={`hgc-${index}`}>
                                    <GameCard suite={c.suite} rank={c.rank} />
                                </Paper>
                            ))}
                        </Stack>
                    </Grid>

                    <Grid item sm={6} md={6} lg={6} sx={{ justifyContent: 'center', display: 'flex' }}>
                        <Stack direction="row" sx={{ position: 'relative', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                            {user?.map((c, index) => (
                                <Paper sx={{ p: '4px', borderRadius: '12px', height: '230px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    style={{
                                        position: 'absolute',
                                        right: `${index * 60}px`,
                                        zIndex: house.length + index,
                                    }}
                                    key={`ugc-${index}`}>
                                    <GameCard suite={c.suite} rank={c.rank}
                                    />
                                </Paper>

                            ))}
                        </Stack>
                    </Grid>

                    <Grid item sm={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center', height: { sm: '120px' } }}>
                        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 4 }}>
                            {isDrawDisabled ? (null) : (<Button variant="game" sx={{ width: '240px' }} onClick={handleDraw}>{t('draw')}</Button>)}
                            {isGameOver || areHitStandDisabled ? (null) : (
                                <>
                                    <Button variant="game" sx={{ width: '120px' }} onClick={handleHit}>{t('hit')}</Button>
                                    <Button variant="game" sx={{ width: '120px' }} onClick={handleStand}>{t('stand')}</Button>
                                </>
                            )}
                        </Stack>
                    </Grid>

                </Grid >
            </Stack >
        </>
    )
};

export default Blackjack;