import React from 'react';
import '../../../assets/style/css/CheckmarkCircle.css';

const CheckmarkCircle = () => {
  return (
    <div className="checkmark-circle">
      <div className="background"></div>
      <div className="checkmark draw"></div>
    </div>
  );
};

export default CheckmarkCircle;