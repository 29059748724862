import React from 'react';
import { Box } from '@mui/material';

function BoardingWrapper({ children, justifyContent, bgcolor, color }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: justifyContent || null,
        minHeight: '100vh',
        bgcolor: bgcolor || 'primary.main',
        color: color || 'white',
        p: 6,
      }}
    >
      {children}
    </Box>
  );
}

export default BoardingWrapper;


