import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

import { Box, Typography, CircularProgress } from '@mui/material';


const CircularTimer = ({ lastPlayed = dayjs().subtract(1, 'day'), onExpiration = () => { } }) => {
    const lastPlayedRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [value, setValue] = useState('');
    const [showProgress, setShowProgress] = useState(false);

    function checkAndUpdate() {
        const now = dayjs();
        const lastPlayed = lastPlayedRef.current;

        const timeDiffInSeconds = dayjs(lastPlayed).add(12, 'hours').diff(now, 'seconds');

        if (timeDiffInSeconds <= 0) {
            setShowProgress(false);
            onExpiration();
            return;
        }

        const twelveHoursInSeconds = 12 * 60 * 60;
        const percentProgress = Math.ceil((1 - timeDiffInSeconds / twelveHoursInSeconds) * 100);
        const duration = dayjs.duration(timeDiffInSeconds, 'seconds');

        setProgress(percentProgress);
        setValue(duration.format('HH:mm:ss'));
    };

    useEffect(() => {
        lastPlayedRef.current = lastPlayed;
    }, [lastPlayed]);

    useEffect(() => {
        const now = dayjs();
        if (dayjs(lastPlayed).add(12, 'hours').isBefore(now)) {
            setShowProgress(false);
        } else {
            checkAndUpdate();
            setShowProgress(true);
        }
    }, [lastPlayed]);

    useEffect(() => {
        const timer = setInterval(() => {
            checkAndUpdate()
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [lastPlayed, showProgress, lastPlayedRef]);

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {showProgress && (
                <>
                    {/* <CircularProgress
                        variant="determinate"
                        color="primary"
                        value={progress}
                        size={80}
                        thickness={5}
                        style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '100%',
                            boxShadow: 'inset 0 0 0px 12px #dbdcf6',
                            backgroundColor: 'transparent',
                        }}
                    /> */}
                    {/* <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    > */}
                    <Typography
                        variant="caption"
                        component="div"
                        color="neutral.100"
                    >{`${value}`}</Typography>
                    {/* </Box> */}
                </>
            )}
        </Box>
    );
};

export default CircularTimer;