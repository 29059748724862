import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';



const NotFoundPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                bgColor: '#5854ec',
                backgroundImage: 'linear-gradient(0deg, #5854ec 0%, #080404 100%)',
            }}
        >
            <Typography sx={{ fontSize: '12rem', color: '#fff' }}>404</Typography>
            <Typography variant="h5" sx={{ mb: 1, color: '#fff' }}>Page Not Found</Typography>
            <Typography variant="body1" sx={{ mb: 2, color: '#fff' }}>Sorry, the page you are looking for does not exist.</Typography>
            <Button component={Link} to="/" sx={{
                mt: 2, padding: '10px 18px', backgroundColor: 'white', borderRadius: '10px', fontWeight: 'bold', color: '#080404',
                '&:hover': { backgroundColor: '#eeeeee', color: '#080404' }
            }}>
                Back to Home
            </Button>
        </Box>
    );
};

export default NotFoundPage;