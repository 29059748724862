'use strict';

const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const specialCharactersRegexp = /[^\w\s]/;
const digitsRegexp = /\d/;

export const isValidEmail = (email) => {
    return email !== '' && emailRegexp.test(email);
};

export const isValidPassword = (password) => {
    const empty = password === '';
    const tooShort = password.length < 8;
    const containsSpecialChar = specialCharactersRegexp.test(password);
    const containsDigit = digitsRegexp.test(password);
    return !empty && !tooShort && containsSpecialChar && containsDigit;
};
