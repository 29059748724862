import { React } from 'react';
import { animated, useSpring } from '@react-spring/web';

const Icon = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="50pt" height="50pt" viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M850 4684 c-126 -19 -202 -46 -300 -109 -121 -78 -224 -205 -300
-369 -51 -110 -72 -202 -72 -311 0 -181 60 -318 209 -477 35 -37 63 -70 63
-73 0 -3 -16 -37 -35 -76 -230 -456 -318 -1016 -225 -1440 112 -517 513 -939
1120 -1179 475 -189 1157 -261 1745 -185 858 112 1480 478 1761 1038 55 108
95 228 125 367 32 149 32 463 1 660 -41 253 -121 509 -223 712 -22 43 -39 85
-39 94 0 9 33 51 73 93 83 88 135 168 168 261 19 55 23 86 23 200 1 115 -3
146 -22 210 -44 140 -128 274 -243 388 -130 130 -243 182 -409 190 -77 3 -123
0 -170 -12 -147 -37 -300 -138 -406 -268 -26 -33 -55 -58 -65 -58 -10 0 -68
20 -129 45 -295 121 -693 186 -1045 171 -323 -14 -566 -66 -885 -189 -43 -16
-72 -22 -85 -17 -11 5 -57 46 -102 93 -132 137 -250 207 -393 233 -75 13 -94
14 -140 8z m1946 -325 c705 -69 1262 -404 1629 -980 306 -479 427 -1091 310
-1556 -153 -604 -786 -1049 -1645 -1158 -299 -37 -530 -52 -675 -44 -416 26
-744 93 -1055 216 -259 102 -454 226 -626 397 -255 256 -377 531 -391 886 -31
741 372 1515 997 1915 110 71 322 170 457 214 162 54 360 93 573 114 82 8 325
6 426 -4z"/>
        <path d="M1595 3299 c-292 -43 -552 -264 -656 -559 -114 -322 -16 -686 218
-812 160 -87 343 -72 574 49 233 122 391 311 473 566 123 378 -50 699 -406
757 -72 11 -118 11 -203 -1z m315 -307 c46 -25 96 -99 111 -167 10 -47 10 -57
-5 -80 -28 -43 -59 -48 -164 -31 -124 21 -152 20 -276 -9 -131 -31 -184 -32
-220 -4 -64 50 -10 124 150 203 198 97 331 126 404 88z"/>
        <path d="M3345 3303 c-112 -15 -232 -72 -308 -148 -59 -57 -118 -162 -138
-245 -19 -77 -15 -220 10 -312 78 -299 254 -511 535 -644 266 -125 456 -111
620 46 160 153 205 381 136 678 -62 264 -243 476 -491 575 -108 43 -259 64
-364 50z m100 -320 c118 -37 282 -120 327 -165 45 -45 49 -78 13 -113 -32 -32
-74 -32 -217 0 -118 27 -191 31 -250 15 -20 -6 -64 -11 -98 -13 -57 -2 -64 0
-87 27 -21 25 -24 36 -19 70 10 61 53 143 92 172 63 48 104 49 239 7z"/>
        <path d="M2396 2405 c-151 -29 -226 -96 -226 -201 0 -36 8 -63 30 -101 29 -51
67 -84 213 -186 85 -61 87 -77 15 -142 -95 -85 -223 -135 -348 -135 -37 0
-110 11 -172 27 -124 31 -151 27 -181 -25 -42 -72 5 -124 145 -162 228 -62
440 -23 636 117 l72 52 58 -42 c211 -159 421 -194 686 -116 86 26 116 54 116
109 0 26 -7 47 -20 60 -33 33 -66 35 -154 9 -113 -33 -284 -33 -368 0 -70 27
-197 116 -217 153 -19 34 -13 42 100 119 159 109 219 194 206 292 -6 44 -49
103 -93 129 -84 49 -345 72 -498 43z"/>
      </g>
    </svg>

  );
};

const AnimatedLogo = () => {
  const { scale } = useSpring({
    from: { scale: 1 },
    to: { scale: 0.6 },
    config: { tension: 200, friction: 50 },
    delay: 100,
    yoyo: true,
    loop: true,
  });
  const animatedStyle = {
    transform: scale.interpolate((s) => `scale(${s})`),
  };

  return (
    <>
      <animated.div style={animatedStyle}>
        <Icon />
      </animated.div>
    </>
  );
};

export default AnimatedLogo;
