import React from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(to right, rgb(88, 84, 236) 0%, rgb(47, 47, 136) 80%)',
    },

  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(to right, rgb(88, 84, 236) 0%, rgb(47, 47, 136) 80%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

export const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 38,
  height: 38,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  '&.active': {
    backgroundImage: 'linear-gradient(to bottom right, rgb(88, 84, 236) 40%, rgb(47, 47, 136) 90%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },

  '&.completed': {
    backgroundImage: 'linear-gradient(to bottom right, rgb(88, 84, 236) 40%, rgb(47, 47, 136) 90%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
}));


export const ArrowBtnBox = styled(Box)(({ theme, left, right }) => ({
  position: 'absolute',
  bottom: '50%',
  transform: 'translateX(-50%)',
  ...(left ? { left } : {}),
  ...(right ? { right } : {}),
}));
