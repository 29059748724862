import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Avatar,
    TextField,
    Stack,
    Paper,
    Box,
    Typography,
    Modal,
    ClickAwayListener,
    Button,
    IconButton,
    InputAdornment,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import MailIcon from '@mui/icons-material/Mail';
import EmailIcon from '@mui/icons-material/Email';

import { isValidEmail } from '../../Common/validation';
import panda from '../../../assets/images/logo/satspanda.png';

const EMPTY_FRIEND = {
    email: '',
    error: false,
    helperText: 'Valid email is required',
};

const InviteFriendsModal = ({ open, onClose = () => { }, onSend = () => { } }) => {
    const { t } = useTranslation();

    const [friends, setFriends] = useState([{ ...EMPTY_FRIEND }]);
    const [shrink, setShrink] = useState(false);

    const prehandleClose = (event) => {
        setFriends([{ ...EMPTY_FRIEND }]);
        onClose(event);
    };

    const prehandleSend = () => {
        const copyFriends = [...friends];
        for (let i = 0; i < friends.length; i++) {
            copyFriends[i].error = !isValidEmail(friends[i].email);
            copyFriends[i].helperText = 'Valid email is required';
        }

        if (copyFriends.some((cf) => cf.error)) {
            setFriends(copyFriends);
            return;
        } else {
            onSend(friends);
            setFriends([{ ...EMPTY_FRIEND }]);
        }
    };

    const handleItemChange = (index) => (event) => {
        const copyFriends = [...friends];
        copyFriends[index].email = event.target.value;
        copyFriends[index].error = !isValidEmail(copyFriends[index].email)
        setFriends(copyFriends);
    };

    return (
        <Modal open={open} onClose={prehandleClose} on>
            <Paper
                sx={{
                    width: { xs: '340px', sm: '500px' },
                    borderRadius: '12px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        px: 3,
                        py: 2,
                    }}
                >
                    <Avatar
                        sx={{
                            bgcolor: 'primary.main',
                            width: 56,
                            height: 56,
                            m: '15px auto',
                        }}
                    >
                        <img
                            src={panda}
                            alt="panda-logo"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </Avatar>
                    <Typography
                        id="modal-modal-title"
                        variant="font4"
                        sx={{ textAlign: 'center' }}
                    >
                        {t('invite_your_friends_to_join_satspanda')}
                    </Typography>
                    <Stack direction="column" alignItems="center" sx={{ mt: 2, width: '100%' }}>
                        {friends.map((f, index) => (
                            <Stack direction="row" sx={{ width: '60%', mb: '8px', position: 'relative' }} key={`if-${index}`}>
                                <TextField fullWidth label={t('email_address')}
                                    onFocus={() => setShrink(true)}
                                    InputLabelProps={{ sx: { ml: shrink ? 0 : 4.5 }, shrink }}
                                    value={friends[index].email}
                                    onChange={handleItemChange(index)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    error={f.error}
                                    helperText={f.error ? f.helperText : ''}
                                />
                                {index === (friends.length - 1) && index !== 0 && (
                                    <IconButton sx={{ position: 'absolute', right: -50, top: '50%', transform: 'translateY(-50%)' }} onClick={() => setFriends([...friends].toSpliced(index, 1))}>
                                        <PersonRemoveIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        ))}
                    </Stack>
                    <Stack direction="row" sx={{ width: '60%', gap: '10px', mt: 2, mb: 1 }}>
                        <IconButton onClick={() => setFriends([...friends, { ...EMPTY_FRIEND }])}
                            sx={{ '&:hover': { backgroundColor: 'transparent', color: 'inherit', borderRadius: '8px' }, width: '30%', border: '1px solid #E5E7EB', borderRadius: '8px' }}>
                            <PersonAddIcon />
                        </IconButton>
                        <Button
                            startIcon={<MailIcon />}
                            variant="small"
                            sx={{ width: '70%', padding: '10px 20px' }}
                            onClick={prehandleSend}
                        >
                            {t('send')}
                        </Button>
                    </Stack>

                </Box>
            </Paper>
        </Modal>
    )
};

export default InviteFriendsModal;