import React from 'react';
import { Paper } from '@mui/material';

const DashboardPaper = ({ children, px, bgcolor, bgImage, width }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        height: '100%',
        width: width || null,
        borderRadius: '16px',
        backgroundColor: bgcolor || 'common.white',
        backgroundImage: bgImage || null,
        px: px || 4,
        py: 2,
      }}
    >
      {children}
    </Paper>
  );
};

export default DashboardPaper;
