import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const fetchTopEarners = createAsyncThunk('leaderboard/topEarners', async () => {
    const response = await client('/v1/leaderboard/top-earners', { method: 'get' });
    return response.data;
});

const INITIAL_STATE = {
    status: 'idle',
    topEarners: [],
};

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: INITIAL_STATE,
    extraReducers: (builder) =>
        builder
            .addCase(fetchTopEarners.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchTopEarners.fulfilled, (state, action) => {
                state.status = 'idle';
                state.topEarners = action.payload;
            })
            .addCase(fetchTopEarners.rejected, (state, action) => {
                state.status = 'idle';
            })
});

export default leaderboardSlice.reducer;