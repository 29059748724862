import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getAuth, signInWithEmailAndPassword, getIdToken } from 'firebase/auth';

import { Avatar, Button, TextField, FormControlLabel, Checkbox, Link, Box, Grid, Typography } from '@mui/material';

import { fetchUser } from '../../redux/slices/user';

import Copyright from './Copyright';
import StandardImageList from './StandardImageList';
import AuthWrapper from './AuthWrapper';
import FooterBelowOr from './FooterBelowOr';
import { isValidEmail, isValidPassword } from '../../ui-components/Common/validation';

import panda from '../../assets/images/logo/satspanda.png';

const SignInSide = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  auth.useDeviceLanguage();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user, status: { user: userStatus } } = useSelector((state) => state.user);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPassworError] = useState(false);
  const [emailHelper, setEmailHelper] = useState('');
  const [passwordHelper, setPasswordHelper] = useState('');
  const [signinError, setSigninError] = useState('');

  useEffect(() => {
    if (!user) return;

    if (user.passedInitialOnboarding) {
      navigate('/');
    } else {
      navigate('/onboarding');
    }
  }, [navigate, user]);

  const handleSignInWithEmail = (event) => {
    event.preventDefault();
    setEmailError(false);
    setPassworError(false);
    setEmailHelper('');
    setPasswordHelper('');

    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (!isValidEmail(email)) {
      setEmailError(true);
      setEmailHelper('Please enter a valid email');
      return;
    }
    // if (!isValidPassword(password)) {
    //   setPassworError(true);
    //   setPasswordHelper('Please enter a valid password');
    //   return;
    // }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const uid = user.uid;
        getIdToken(user)
          .then((id_token) => {
            localStorage.setItem('satspanda:id_token', id_token);
          })
          .then(() => {
            dispatch(fetchUser(uid));
          })
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/user-not-found':
            setSigninError('User with such email does not exist.');
            break;
          case 'auth/wrong-password':
            setSigninError('Wrong password.');
            break;
          case 'auth/too-many-requests':
            setSigninError(
              'Too many attempts. Please try again later or reset your password.'
            );
            break;
          default:
            setSigninError('Unknown error. Please try again later.');
        }
      });
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <AuthWrapper>
        <Box
          sx={{
            mx: 5,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Avatar
            sx={{
              bgcolor: 'primary.main',
              width: 56,
              height: 56,
              m: '15px auto',
            }}
          >
            <img src={panda} alt="panda-logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Avatar>
          <div style={{ textAlign: 'left' }}>
            <Typography
              component="h1"
              variant="font3Slim"
              color="primary.main"
              sx={{ mb: 1, textAlign: 'center' }}
            >
              {t('get_paid')}
            </Typography>
            <Typography color="neutral.700" variant="font5">
              {t('sign_in_with_your_existing_satspanda_or_google_account')}
            </Typography>
          </div>
          <Box component="form" noValidate onSubmit={handleSignInWithEmail}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('email_address')}
              name="email"
              autoComplete="email"
              autoFocus
              error={emailError}
              helperText={emailHelper}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
              error={passwordError}
              helperText={passwordHelper}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  size="small"
                  disableRipple
                />
              }
              label={
                <Typography variant="font5" color="neutral.500">
                  {t('remember_me')}
                </Typography>
              }
            />
            <br />
            {signinError ? (
              <Typography variant="font5" style={{ color: '#d32f2f' }}>
                {signinError}
              </Typography>
            ) : (
              ''
            )}
            <Button
              type="submit"
              fullWidth
              variant="signin"
              sx={{ mt: 2, mb: 2 }}
              disabled={userStatus !== 'idle'}
            >
              {t('sign_in')}
            </Button>
            <FooterBelowOr>
              <Grid container sx={{ mt: 2, mb: 2 }}>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    {t('forgot_password')}?
                  </Link>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="primary.main"
                    align="center"
                  >
                    {t('dont_have_an_account')}{' '}
                    <Link href="signup" style={{ textDecoration: 'underline' }}>
                      {t('sign_up')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </FooterBelowOr>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </AuthWrapper>
      <AuthWrapper display={{ xs: 'none', sm: 'none', md: 'block' }}>
        <Box
          sx={{
            mx: 5,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StandardImageList></StandardImageList>
        </Box>
      </AuthWrapper>
    </Grid>
  );
};

export default SignInSide;
