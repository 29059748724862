import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Grid, Divider } from '@mui/material';

import DashboardContainer from '../../ui-components/Common/Container/DashboardContainer';
import JourneyTree from './JourneyTree';
import JourneyCard from '../../ui-components/Learning/Cards/JourneyCard';
import JourneyCardSkeleton from '../../ui-components/Learning/Skeleton/JourneyCardSkeleton';
import EmptyJourneys from '../../ui-components/Learning/Paper/EmptyJourneys';

import { fetchUserJourneys, fetchUserStats } from '../../redux/slices/user';
import { fetchLanguages } from '../../redux/slices/language';


const Learning = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { journeyId, lessonId } = useParams();
    const [prebuiltJourneyId, setPrebuiltJourneyId] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const user = useSelector((state) => state.user.userFromFirebase);
    const journeys = useSelector((state) => state.user.journeys);
    const languages = useSelector((state) => state.languages.languages);
    const journeyStatus = useSelector((state) => state.user.status.userStats);

    const theJourney = journeys.find((j) => j.id === journeyId);
    const isSubscribedToJourneys = journeys.length > 0;

    useEffect(() => {
        if (user && user.uid) {
            dispatch(fetchUserStats({ uuid: user.uid }));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (user && user.uid) {
            dispatch(fetchUserJourneys(user.uid));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (!languages || languages.length === 0) {
            dispatch(fetchLanguages());
        }
    });

    useEffect(() => {
        if (searchParams && searchParams.has('prebuiltJourneyId')) {
            setPrebuiltJourneyId(searchParams.get('prebuiltJourneyId'));
            setSearchParams({});
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (prebuiltJourneyId) {
            const journeyFromPrebuilt = journeys.find((j) => j.prebuiltSource === prebuiltJourneyId)
            navigate(`/learning/${journeyFromPrebuilt.id}`);
        }
    }, [prebuiltJourneyId, journeys, navigate]);

    const handleFind = () => {
        navigate('/onboarding');
    };

    return (
        <DashboardContainer justifyContent={'center'} minHeight={'100vh'}>
            {!journeyId ? (
                <>
                    {journeyStatus === 'loading' ? (
                        <JourneyCardSkeleton />
                    ) : isSubscribedToJourneys && journeyStatus === 'idle' ? (
                        <>
                            <Grid item xs={12}>
                                <Divider textAlign="left" sx={{ mb: 3, color: 'neutral.500', fontSize: '14px' }}>In progress</Divider>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Grid container spacing={4} sx={{ maxWidth: { md: '900px', lg: '1200px' } }}>
                                        {journeys.map((j, index) => (
                                            <Grid item sm={12} md={6} lg={4} key={`jp-${index}`} sx={{ display: { sm: 'flex' }, justifyContent: { sm: 'center' } }}>
                                                <JourneyCard journey={j} languages={languages} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 3, mb: 3, color: 'neutral.500', fontSize: '14px' }}>
                                <Divider textAlign="left">Finished</Divider>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <EmptyJourneys handleFind={handleFind} />
                        </>
                    )}
                </>
            ) : (
                <>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <JourneyTree journey={theJourney} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        {lessonId ? (
                            <>
                            </>
                        ) : (
                            <>
                                Start a lesson
                            </>
                        )}
                    </Grid> */}
                </>
            )}
        </DashboardContainer>
    );
};

export default Learning;