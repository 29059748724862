import _1c from '../../assets/images/games/blackjack/1c.png';
import _1d from '../../assets/images/games/blackjack/1d.png';
import _1h from '../../assets/images/games/blackjack/1h.png';
import _1s from '../../assets/images/games/blackjack/1s.png';

import _2c from '../../assets/images/games/blackjack/2c.png';
import _2d from '../../assets/images/games/blackjack/2d.png';
import _2h from '../../assets/images/games/blackjack/2h.png';
import _2s from '../../assets/images/games/blackjack/2s.png';

import _3c from '../../assets/images/games/blackjack/3c.png';
import _3d from '../../assets/images/games/blackjack/3d.png';
import _3h from '../../assets/images/games/blackjack/3h.png';
import _3s from '../../assets/images/games/blackjack/3s.png';

import _4c from '../../assets/images/games/blackjack/4c.png';
import _4d from '../../assets/images/games/blackjack/4d.png';
import _4h from '../../assets/images/games/blackjack/4h.png';
import _4s from '../../assets/images/games/blackjack/4s.png';

import _5c from '../../assets/images/games/blackjack/5c.png';
import _5d from '../../assets/images/games/blackjack/5d.png';
import _5h from '../../assets/images/games/blackjack/5h.png';
import _5s from '../../assets/images/games/blackjack/5s.png';

import _6c from '../../assets/images/games/blackjack/6c.png';
import _6d from '../../assets/images/games/blackjack/6d.png';
import _6h from '../../assets/images/games/blackjack/6h.png';
import _6s from '../../assets/images/games/blackjack/6s.png';

import _7c from '../../assets/images/games/blackjack/7c.png';
import _7d from '../../assets/images/games/blackjack/7d.png';
import _7h from '../../assets/images/games/blackjack/7h.png';
import _7s from '../../assets/images/games/blackjack/7s.png';

import _8c from '../../assets/images/games/blackjack/8c.png';
import _8d from '../../assets/images/games/blackjack/8d.png';
import _8h from '../../assets/images/games/blackjack/8h.png';
import _8s from '../../assets/images/games/blackjack/8s.png';

import _9c from '../../assets/images/games/blackjack/9c.png';
import _9d from '../../assets/images/games/blackjack/9d.png';
import _9h from '../../assets/images/games/blackjack/9h.png';
import _9s from '../../assets/images/games/blackjack/9s.png';

import _10c from '../../assets/images/games/blackjack/10c.png';
import _10d from '../../assets/images/games/blackjack/10d.png';
import _10h from '../../assets/images/games/blackjack/10h.png';
import _10s from '../../assets/images/games/blackjack/10s.png';

import _jc from '../../assets/images/games/blackjack/jc.png';
import _jd from '../../assets/images/games/blackjack/jd.png';
import _jh from '../../assets/images/games/blackjack/jh.png';
import _js from '../../assets/images/games/blackjack/js.png';

import _qc from '../../assets/images/games/blackjack/qc.png';
import _qd from '../../assets/images/games/blackjack/qd.png';
import _qh from '../../assets/images/games/blackjack/qh.png';
import _qs from '../../assets/images/games/blackjack/qs.png';

import _kc from '../../assets/images/games/blackjack/kc.png';
import _kd from '../../assets/images/games/blackjack/kd.png';
import _kh from '../../assets/images/games/blackjack/kh.png';
import _ks from '../../assets/images/games/blackjack/ks.png';

import _b from '../../assets/images/games/blackjack/b.png';

export default {
    _1c,
    _1d,
    _1h,
    _1s,

    _2c,
    _2d,
    _2h,
    _2s,

    _3c,
    _3d,
    _3h,
    _3s,

    _4c,
    _4d,
    _4h,
    _4s,

    _5c,
    _5d,
    _5h,
    _5s,

    _6c,
    _6d,
    _6h,
    _6s,

    _7c,
    _7d,
    _7h,
    _7s,

    _8c,
    _8d,
    _8h,
    _8s,

    _9c,
    _9d,
    _9h,
    _9s,

    _10c,
    _10d,
    _10h,
    _10s,

    _jc,
    _jd,
    _jh,
    _js,

    _qc,
    _qd,
    _qh,
    _qs,

    _kc,
    _kd,
    _kh,
    _ks,

    _b,
};