import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import VerticalAppbar from '../Navbar/VerticalAppbar';


const MainLayout = () => {
  const location = useLocation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'dark.main',
          width: '100%',
        }}
      >
        <Box component="nav" sx={{ flexShrink: 0 }}>
          <VerticalAppbar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            marginLeft: '82px',
            backgroundColor: '#F0F4F9',
            borderRadius: '16px 0 0 16px',
          }}
        >
          <Outlet />
        </Box>
      </Box >
    </>
  );
};

export default MainLayout;
