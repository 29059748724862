import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const fetchJourneys = createAsyncThunk('journeys/fetchJourneys', async ({ language, scale }) => {
    const response = await client(`/v1/journeys?language=${language}&scale=${scale}`, { method: 'get' });
    return response.data;
});

const INITIAL_STATE = {
    status: 'idle',
    journeys: {},
};

const languagesSlice = createSlice({
    name: 'languages',
    initialState: INITIAL_STATE,
    extraReducers: (builder) =>
        builder
            .addCase(fetchJourneys.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchJourneys.fulfilled, (state, action) => {
                state.status = 'idle';
                state.journeys = {
                    ...state.journeys,
                    [action.meta.arg.language]: action.payload,
                };
            })
            .addCase(fetchJourneys.rejected, (state, action) => {
                state.status = 'idle';
            })
});

export default languagesSlice.reducer;
