import React from 'react';
import { Box } from '@mui/material';
import ModuleCustomAccordion from '../../ui-components/Learning/Accordion/ModuleCustomAccordion';


const JourneyTree = ({ journey }) => {

    return (
        <>
            <Box sx={{ minHeight: '100vh', width: '600px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {journey?.JourneyModules?.map((jm, index) => {
                    return (
                        <ModuleCustomAccordion
                            journeyId={journey.id}
                            journeyModule={jm}
                            position={index}
                            key={`jm-${index}`}
                        />
                    )
                })}
            </Box>
        </>
    );
};

export default JourneyTree;