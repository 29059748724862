import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const fetchLesson = createAsyncThunk('lessons/fetch', async (lessonId) => {
    const response = await client(`/v1/lessons/${lessonId}`, { method: 'get' });
    return response.data;
});

export const submitLesson = createAsyncThunk('lessons/submit', async ({ lessonId, answers }) => {
    const response = await client(`/v1/lessons/${lessonId}/submit`, {
        method: 'post',
        body: {
            lessonId,
            answers,
        },
    });
    return response.data;
});


const INITIAL_STATE = {
    status: 'idle',
    lesson: {},
};

const lessonSlice = createSlice({
    name: 'lesson',
    initialState: INITIAL_STATE,
    extraReducers: (builder) =>
        builder
            .addCase(fetchLesson.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchLesson.fulfilled, (state, action) => {
                state.status = 'idle';
                state.lesson = action.payload;
            })
            .addCase(fetchLesson.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(submitLesson.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(submitLesson.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(submitLesson.rejected, (state, action) => {
                state.status = 'idle';
            })
});


export default lessonSlice.reducer;