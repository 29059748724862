import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, createUserWithEmailAndPassword, getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { Avatar, TextField, FormControlLabel, Checkbox, Link, Box, Grid, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Copyright from './Copyright';
import AuthWrapper from './AuthWrapper';
import StandardImageList from './StandardImageList';
import FooterBelowOr from './FooterBelowOr';

import panda from '../../assets/images/logo/satspanda.png';

import { fetchInvite } from '../../redux/slices/invites';
import { setUserFromFirebase, fetchUser, updateUser } from '../../redux/slices/user';
import { isValidEmail, isValidPassword } from '../../ui-components/Common/validation';

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [password, setPassword] = useState('');
  const [inviteId, setInviteId] = useState(null);

  const [isPasswordError, setIsPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');

  const { invite, status } = useSelector((state) => state.invites);

  useEffect(() => {
    const inviteId = searchParams.get('inviteId');
    if (inviteId) {
      setInviteId(inviteId);
      dispatch(fetchInvite(inviteId))
    }
  }, [searchParams]);

  useEffect(() => {
    if (invite && invite.email) {
      setEmailDisabled(true);
      setEmail(invite.email);
    }
  }, [invite]);

  const handleSignUp = (event) => {
    event.preventDefault();
    setIsError(false);
    setIsPasswordError(false);
    setPasswordHelperText('');
    setIsEmailError(false);
    setEmailHelperText('');
    setIsLoading(true);

    if (!isValidEmail(email)) {
      setIsEmailError(true);
      setEmailHelperText('Must be a valid email');
      setIsLoading(false);
      return;
    }

    if (!isValidPassword(password)) {
      setIsPasswordError(true);
      setPasswordHelperText('Password must be at least 8 characters long, contain 1 digit and 1 special character');
      setIsLoading(false);
      return;
    }

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        dispatch(setUserFromFirebase(auth.currentUser));
        getIdToken(user)
          .then((id_token) => localStorage.setItem('satspanda:id_token', id_token))
          .then(() => dispatch(updateUser({ uuid: user.uid, firstName, lastName })))
          .then(() => dispatch(fetchUser(user.uid)))
          .then(() => navigate('/signup-complete'));
      })
      .catch((error) => {
        setIsError(true);
        setIsLoading(false);
        switch (error.code) {
          case 'auth/email-already-in-use':
            setErrorText(`User with email ${email} already exists.`);
            break;
          default:
            console.error(error);
            setErrorText('Unknown error. Please try again later.');
        }
      });
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <AuthWrapper>
        <Box
          sx={{
            mx: 5,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Avatar
            sx={{
              bgcolor: 'primary.main',
              width: 56,
              height: 56,
              m: '15px auto',
            }}
          >
            <img src={panda} alt="panda-logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Avatar>
          <div style={{ textAlign: 'left' }}>
            <Typography
              component="h1"
              variant="font3Slim"
              color="primary.main"
              sx={{ mb: 1 }}
            >
              {t('earn_sats_while_learning_a_language')}
            </Typography>
            <Typography color="neutral.700" variant="font5">
              {t('sign_up_with_your_email_gogle_or_anonymously')}
            </Typography>
          </div>
          <Box
            component="form"
            noValidate
            onSubmit={handleSignUp}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  label={t('first_name')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="family-name"
                  required
                  fullWidth
                  label={t('last_name')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  required
                  fullWidth
                  label={t('email_address')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={emailDisabled}
                  error={isEmailError}
                  helperText={emailHelperText}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="new-password"
                  required
                  fullWidth
                  label={t('password')}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={isPasswordError}
                  helperText={passwordHelperText}
                />
              </Grid>
              {inviteId && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label={t('invite_code')}
                    id="inviteCode"
                    value={inviteId}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="allowExtraEmails"
                      color="primary"
                      size="small"
                      disableRipple
                    />
                  }
                  label={
                    <Typography variant="font5" color="neutral.500">
                      {t('i_want_to_receive_inspiration_marketing_promotions_and_updates_via_email')}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="signin"
              sx={{ mt: 3, mb: 2 }}
              loading={isLoading}
            >
              {t('sign_up')}
            </LoadingButton>
            {isError && (
              <>
                <Typography style={{ color: 'red' }}>{errorText}</Typography>
              </>
            )}
            <FooterBelowOr>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Typography
                    variant="body2"
                    color="primary.main"
                    align="center"
                  >
                    {t('already_have_an_account')}?{' '}
                    <Link href="/signin" variant="body2">
                      {t('sign_in')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </FooterBelowOr>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>

      </AuthWrapper>
      <AuthWrapper display={{ xs: 'none', sm: 'none', md: 'block' }}>
        <Box
          sx={{
            mx: 5,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StandardImageList></StandardImageList>
        </Box>
      </AuthWrapper>
    </Grid>
  );
}
