import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import JourneyButton from '../../Common/Buttons/JourneyButton';
import book from '../../../assets/images/icons/book-icon.png';

const EmptyJourneys = ({ handleFind }) => {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ height: '180px', width: '600px', bgcolor: 'common.white', borderRadius: '14px' }}>
                <Grid container sx={{ height: '100%' }}>
                    <Grid item lg={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', px: 4, py: 3 }}>
                        <Stack direction="column">
                            <Typography sx={{ mb: '3px' }} variant="font4">Sign up and track your journey progress</Typography>
                            <Typography color="neutral.500">You can sign up right here</Typography>
                        </Stack>
                        <JourneyButton text="Find journey" icon={<SearchIcon sx={{ fontSize: '24px' }} />} onClick={handleFind} />
                    </Grid>
                    <Grid item lg={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={book} style={{ width: '80px', height: '80px' }} alt="Book" />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default EmptyJourneys;