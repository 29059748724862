import React from 'react';
import { Grid, Stack, Button, Typography } from '@mui/material';

const Stage2 = ({ language, journeys, handleJourneySelection }) => {

    const languageJourneys = journeys[language];

    return (
        <div
            style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '1.5rem',
            }}
        >
            {languageJourneys.map((journey) => (
                <Button
                    key={journey.id}
                    variant="langlevel"
                    onClick={() => handleJourneySelection(journey)}
                >
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography>{journey.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack>
                                <Typography color="neutral.400" fontSize={12}>Completion bonus: </Typography>
                                <Typography>⚡️ {journey.satsbonus}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                </Button>
            ))}
        </div>
    );
};

export default Stage2;
