import axios from 'axios';

export const SATSPANDA_API = process.env.REACT_APP_SATSPANDA_API;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

const client = async (endpoint, { method = 'get', headers = [], body, ...otherConfigs }) => {
    const url = `${SATSPANDA_API}${endpoint}`;
    const id_token = localStorage.getItem('satspanda:id_token') || null;
    return axios({
        method,
        url,
        headers: {
            'Authorization': `Bearer ${id_token}`,
            ...headers,
        },
        ...body && { data: body },
        ...otherConfigs,
    }).catch((err) => {
        const { response } = err;
        if (response.status === 401) {
            window.location.href = `${LOGIN_URL}`;
        }
        throw err;
    });
};

export default client;