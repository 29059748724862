import React from 'react';
import { Paper } from '@mui/material';
import mahjongs from '../mahjongs';

const MahjongCard = ({ suite, rank, selected = false, onClick = () => { }, ...props }) => {
    const name = suite === 'h' ? 'h' : `${suite}${rank}`;
    const src = mahjongs[name] ? mahjongs[name] : mahjongs.h;

    return (
        <Paper
            sx={{
                p: '4px',
                borderRadius: '12px',
                ...selected && { background: '#44d0d8' }
            }}
            onClick={onClick}
        >
            <img src={src} {...props} width="100px" height="auto" />
        </Paper>
    )
};

export default MahjongCard;