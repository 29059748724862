import React, { useState } from 'react';
import { ImageList, ImageListItem } from '@mui/material';

import chinese from '../../assets/images/languages/lang-icons/chinese.png';
import american from '../../assets/images/languages/lang-icons/american.png';
import russian from '../../assets/images/languages/lang-icons/russian.png';
import bulgarian from '../../assets/images/languages/lang-icons/bulgarian.png';
import turkish from '../../assets/images/languages/lang-icons/turkish.png';
import portuguese from '../../assets/images/languages/lang-icons/portuguese.png';
import polish from '../../assets/images/languages/lang-icons/polish.png';
import greek from '../../assets/images/languages/lang-icons/greek.png';
import italian from '../../assets/images/languages/lang-icons/italian.png';
import spanish from '../../assets/images/languages/lang-icons/spanish.png';
import french from '../../assets/images/languages/lang-icons/french.png';
import german from '../../assets/images/languages/lang-icons/german.png';

const itemData = [
  {
    img: chinese,
    title: 'Chinese',
  },
  {
    img: american,
    title: 'American',
  },
  {
    img: russian,
    title: 'Russian',
  },
  {
    img: turkish,
    title: 'Turkish',
  },
  {
    img: bulgarian,
    title: 'Bulgarian',
  },
  {
    img: portuguese,
    title: 'Portuguese',
  },
  {
    img: polish,
    title: 'Polish',
  },
  {
    img: greek,
    title: 'Greek',
  },
  {
    img: italian,
    title: 'Italian',
  },
  {
    img: spanish,
    title: 'Spanish',
  },
  {
    img: french,
    title: 'French',
  },
  {
    img: german,
    title: 'German',
  },

];

const StandardImageList = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <ImageList
      sx={{ width: '100%', height: 'auto' }}
      cols={3}
      rowHeight={'auto'}
    >
      {itemData.map((item, index) => {
        const isInRange = index >= 3 && index <= 12;
        const imageStyle = {
          opacity: hoveredIndex === index ? 1 : 0.7,
          filter: isInRange ? 'grayscale(100%)' : 'none',
        };
        return (
          <ImageListItem
            key={item.img}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={imageStyle}
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}




export default StandardImageList;
