import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Typography, Stack, Card, CardContent } from '@mui/material';

import CircularTimer from '../../Common/Timer/CircularTimer';


const MiniGameCard = ({ name, lastPlayed, navUrl, img, width }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [playEnabled, setPlayEnabled] = useState(false);

    const handleTimeExpiration = () => {
        setPlayEnabled(true);
    };

    return (
        <Card sx={{ width: '100%', height: 115, borderRadius: '12px', paddingTop: '10px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '6px', bgcolor: 'dark.main', boxShadow: 'none' }}>
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography variant="font4" noWrap={true} component="div" sx={{ color: 'white', textAlign: 'left' }}>
                    {name}
                </Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: '80%' }}>
                    <Button variant="primary-small" disabled={!playEnabled} onClick={() => navigate(navUrl)}> {t('play')} </Button>
                    {/* dayjs().subtract(60 * 11.995, 'minute')  */}
                    <CircularTimer
                        lastPlayed={lastPlayed}
                        onExpiration={handleTimeExpiration}
                    />
                    <img src={img} width={width} height="auto" />
                </Stack>
            </CardContent>
        </Card>
    );
};

export default MiniGameCard;