import { createTheme } from '@mui/material/styles';
import sizeConfigs from './sizeConfigs';

const palleteColors = {
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  primary: {
    main: '#5854ec',
    mid: '#4643bc',
    dark: '#3f40b5',
    light: '#dbdcf6',
  },
  secondary: {
    main: '#44d0d8',
    dark: '#3dbbc2',
  },
  dark: {
    main: '#080404',
  },
  neutral: {
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  background: {
    cardWrapper: '#F1F1F8',
    cardDescription: '#9CA3AF',
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 2100,
    },
  },
  palette: palleteColors,
  typography: {
    font1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    font2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    font3: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    font3Slim: {
      fontSize: '1.25rem',
    },
    font4: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    font4Slim: {
      fontSize: '1rem',
    },
    font5: {
      fontSize: '0.8rem',
    },
    option: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    comment: {
      fontSize: '0.7rem',
      lineHeight: '0.5',
    },
    font5mid: {
      fontSize: '0.9rem',
    },
    displayText: {
      fontSize: '4rem',
    },
    dashboard: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    }

  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffff',
        },
        input: {
          fontSize: '14px',
          color: 'black',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiTextField-root': {
            marginTop: '8px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'signin' },
          style: {
            backgroundColor: '#5854ec',
            padding: '10.5px 14px',
            color: 'white',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#4643bc',
            },
          },
        },
        {
          props: { variant: 'signin-outline' },
          style: {
            backgroundColor: '#ffff',
            border: '1px solid #5854ec',
            padding: '10.5px 14px',
            color: '#374151',
          },
        },
        {
          props: { variant: 'lang' },
          style: {
            backgroundColor: 'white',
            width: '10rem',
            height: '10rem',
            padding: 0,
            borderRadius: '8px',
            color: '#374151',
            fontSize: '1rem',
            fontWeight: 'bold',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          },
        },
        {
          props: { variant: 'langlevel' },
          style: {
            backgroundColor: 'white',
            width: '16rem',
            padding: '20px 20px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            color: '#374151',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            '&:hover': {
              backgroundColor: '#ff8c0c',
              color: '#ffff',
            },
          },
        },
        {
          props: { variant: 'regular' },
          style: {
            backgroundColor: '#5854ec',
            width: '8rem',
            padding: '10px 10px',
            borderRadius: '8px',
            textAlign: 'center',
            color: 'white',
            fontSize: '0.85rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#4643bc',
              color: '#ffff',
            },
          },
        },
        {
          props: { variant: 'regular-outlined' },
          style: {
            border: '1px solid #5854ec',
            width: '8rem',
            padding: '8px 10px',
            borderRadius: '8px',
            textAlign: 'center',
            color: '#5854ec',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            '&:hover': {
              color: '#4643bc',
              backgroundColor: '#FAFAFA',
            },
          },
        },
        {
          props: { variant: 'small-outlined' },
          style: {
            border: '1px solid #5854ec',
            width: '6rem',
            padding: '8px 10px',
            borderRadius: '8px',
            textAlign: 'center',
            color: '#5854ec',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            '&:hover': {
              color: '#4643bc',
              backgroundColor: '#FAFAFA',
            },
          },
        },
        {
          props: { variant: 'small' },
          style: {
            backgroundColor: '#5854ec',
            width: '6rem',
            padding: '8px 10px',
            borderRadius: '8px',
            textAlign: 'center',
            color: 'white',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#4643bc',
              color: '#ffff',
            },
          },
        },
        {
          props: { variant: 'primary-small' },
          style: {
            backgroundColor: '#ffff',
            width: '6rem',
            padding: '8px 10px',
            borderRadius: '8px',
            textAlign: 'center',
            color: '#080404',
            fontSize: '0.8rem',
            '&:hover': {
              backgroundColor: '#eeeeee',
              color: '#080404',
            },
          },
        },
        {
          props: { variant: 'primary-mid' },
          style: {
            backgroundColor: '#ffff',
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'center',
            color: '#080404',
            fontSize: '0.85rem',
            fontWeight: '500',
            '&:hover': {
              backgroundColor: '#eeeeee',
              color: '#080404',
            },
          },
        },
        {
          props: { variant: 'game' },
          style: {
            backgroundColor: '#080404',
            textAlign: 'center',
            color: '#fff',
            padding: '8px 8px',
            fontSize: '0.85rem',
            fontWeight: 'bold',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: '#eeeeee',
              color: '#080404',
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': {
            padding: 0,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: sizeConfigs.sidebar.width,
          borderRight: '0px',
          backgroundColor: '#111929',
          color: 'white',
        },
      },
    },
    MuiBadge: {
      root: {
        badge: {
          right: -3,
          top: 13,
          border: '2px solid #FFE147',
          padding: '0 4px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid #CBCBCB',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'black',
          fontSize: '12px',
          textTransform: 'none',
        },
        '& .Mui-selected': {
          fontWeight: 'bold',
          color: '#0099FF',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '& .MuiTab-root': {
          fontSize: '14px !important',
          color: '#707175 !important',
        },
        '& .MuiTabs-indicator': {},
      },
    },
  },
});

export default theme;
