import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Stack, Typography } from '@mui/material';
import CheckmarkCircle from '../VisualElements/CheckmarkCircle';


const LessonCompleted = ({ journeyId, score, sats }) => {

    const navigate = useNavigate();

    const handleFinish = () => {
        navigate(`/learning/${journeyId}`);
    };

    return (
        <>
            <Box sx={{ width: '480px', p: '30px', bgcolor: 'white', borderRadius: '12px' }}>
                <Stack direction="column" alignItems="center" gap={4}>
                    <Stack direction="column" alignItems="center" sx={{ mb: 4 }}>
                        <Typography variant="font2">Congratulations 🎉</Typography>
                        <Typography>You’ve  successfully completed the lesson</Typography>
                    </Stack>
                    <Stack direction="column" alignItems="center" sx={{ mb: 2 }} gap={3}>
                        <Typography>Your quiz score is {score}</Typography>
                        <CheckmarkCircle />
                    </Stack>
                    <Stack direction="column" alignItems="center">
                        <Typography>You won {sats} Sats ⚡</Typography>
                        <Typography color="neutral.500">Keep up the great work! </Typography>
                    </Stack>
                    <Button sx={{
                        bgcolor: '#44D0D8', color: 'white', px: '26px', py: '8px', fontWeight: '600',
                        '&:hover': { backgroundColor: '#3dbbc2' }
                    }}
                        onClick={handleFinish}>Finish</Button>
                </Stack>
            </Box>
        </>
    );
};

export default LessonCompleted;