import React, { useState, useEffect } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchLanguages,
  fetchLanguageScales,
  updateUserPortfolio,
} from '../../redux/slices/language';
import { fetchJourneys } from '../../redux/slices/journey';
import { signUpUserToJourney, fetchUserStats } from '../../redux/slices/user';

import BoardingWrapper from '../../ui-components/Common/BoardingWrapper';
import Preloader from '../../ui-components/Common/Preloader/Preloader';
import Stage0 from '../../ui-components/Onboarding/Boxes/Stage0';
import Stage1 from '../../ui-components/Onboarding/Boxes/Stage1';
import Stage2 from '../../ui-components/Onboarding/Boxes/Stage2';
import BackToDashboardButton from '../../ui-components/Common/Buttons/BackToDashboardButton';

const onboardingHeaders = [
  {
    header: 'What language do you want to learn?',
  },
  {
    header: 'What is your current language level?',
    subheader:
      'Please choose from the options so you can start your language journey',
  },
  {
    header: 'We found some journeys for you:',
    subheader: 'Each journey comes with a completion bonus in ⚡️ sat.',
  },
];

const Onboarding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onboardingStage, setOnboardingStage] = useState(0);
  const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const [selectedScale, setSelectedScale] = useState(null);

  const userFromFirebase = useSelector((state) => state.user.userFromFirebase);
  const { languages, languageScales, status: { languages: languagesStatus } } = useSelector((state) => state.languages);
  const { journeys, status: journeyStatus } = useSelector((state) => state.journeys);


  useEffect(() => {
    if (languagesStatus === 'idle') {
      dispatch(fetchLanguages());
    }
  }, []);

  const handleLanguageSelection = (language) => {
    if (language.isEnabled) {
      const languageId = language.locale;
      setSelectedLanguageId(languageId);
      dispatch(fetchLanguageScales(languageId));
      setOnboardingStage((prevStage) => prevStage + 1);
    } else return;
  };

  const handleScaleSelection = (ls) => {
    const scale = ls.index;
    setSelectedScale(scale);
    dispatch(fetchJourneys({ language: selectedLanguageId, scale: selectedScale }));
    setOnboardingStage((prevStage) => prevStage + 1);
  };

  const handleJourneySelection = (option) => {
    dispatch(updateUserPortfolio({ uuid: userFromFirebase.uid, scale: selectedScale, language: selectedLanguageId }));
    dispatch(signUpUserToJourney({ uuid: userFromFirebase.uid, journeyId: option.id }))
      .then(() => dispatch(fetchUserStats({ uuid: userFromFirebase.uid, ignoreCache: true })))
      .then(() => {
        navigate({ pathname: '/learning', search: `?${createSearchParams({ prebuiltJourneyId: option.id })}` });
      });
  };


  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <BoardingWrapper>
      <BackToDashboardButton onClick={handleNavigateToDashboard} />
      {languagesStatus !== 'idle' || journeyStatus !== 'idle' ? (
        <Preloader />
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              maxWidth: '900px',
            }}
          >
            <Typography variant="font4Slim" sx={{ mb: 1 }}>
              Step {onboardingStage + 1}
            </Typography>
            <Typography variant="font1" sx={{ mb: 2 }}>
              {onboardingHeaders[onboardingStage].header}
            </Typography>
            <Typography variant="font4Slim" sx={{ mb: 5 }}>
              {onboardingHeaders[onboardingStage].subheader}
            </Typography>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              {onboardingStage === 0 ? (
                <Stage0
                  languages={languages}
                  handleLanguageSelection={handleLanguageSelection}
                />
              ) : null}
              {onboardingStage === 1 ? (
                <Stage1
                  languageScales={languageScales}
                  selectedLanguageId={selectedLanguageId}
                  handleScaleSelection={handleScaleSelection}
                />
              ) : null}
              {onboardingStage === 2
                ?
                <Stage2
                  language={selectedLanguageId}
                  journeys={journeys}
                  handleJourneySelection={handleJourneySelection}
                />
                : null}
            </div>
          </div>
        </div>
      )}
    </BoardingWrapper>
  );
};

export default Onboarding;
