import React from 'react';
import { Button } from '@mui/material';

import ConditionalTooltip from '../Tooltip/ConditionalTooltip';
import LanguageButton from '../Buttons/LanguageButton';

const Stage0 = ({ languages, handleLanguageSelection }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1.5rem',
      }}
    >
      {languages.map((language) => (
        <ConditionalTooltip
          key={language.locale}
          renderTooltip={!language.isEnabled}
          title={
            <span style={{ fontSize: '16px' }}>
              {language.sorry} We do not support {language.name}{' '}
              {language.emoji} yet, but our team of well-trained developers is
              working hard to get it fixed ASAP.
            </span>
          }
        >
          <span>
            <Button
              key={language.locale}
              variant="lang"
              sx={{
                opacity: language.isEnabled ? 1 : 0.5,
                pointerEvents: language.isEnabled ? 'auto' : 'none',
              }}
              onClick={() => handleLanguageSelection(language)}
            >
              <LanguageButton language={language.name} emoji={language.emoji} />
            </Button>
          </span>
        </ConditionalTooltip>
      ))}
    </div>
  );
};

export default Stage0;
