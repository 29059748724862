import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sendEmailVerification } from 'firebase/auth';
import { useTranslation, Trans } from 'react-i18next';

import { Button, Typography, Box } from '@mui/material';

import BoardingWrapper from '../../ui-components/Common/BoardingWrapper';
import logo from '../../assets/images/logo/satspanda.png';


const SignUpComplete = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.userFromFirebase);
  const email = user?.email;

  useEffect(() => {
    if (user && !user.emailVerified) {
      sendEmailVerification(user);
    }
  }, [user]);

  const handleStart = () => {
    navigate('/onboarding');
  };

  return (
    <BoardingWrapper justifyContent="center" bgcolor="#fff">
      <div style={{ maxWidth: '700px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ mt: 4, bgcolor: 'primary.main', height: '68px', width: '68px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={logo} height="63px" width="63px" />
        </Box>
        <Typography variant="font1" color="primary.main" sx={{ mt: 4, mb: 4, alignSelf: 'center' }}>
          {t('thank_you_for_signing_up_to_satspanda')}
        </Typography>
        <Typography variant="font4Slim" color="neutral.800" sx={{ mb: 8, lineHeight: '1.7' }}>
          {t('your_well_paid_journey_is_just_few_clicks_away')} {' '}
          <Trans i18nKey="we_have_sent_you_a_welcome_email_with_verification" >
            We have sent you a welcome email to <span style={{ fontWeight: 'bold', color: '#5854ec' }}>{{ email }}</span> with a verification link.
          </Trans> {' '}
          {/* We have sent you a welcome email to <span style={{ fontWeight: 'bold', color: '#5854ec' }}>{email}</span> with a verification link. */}
          {t('you_can_start_learning_and_earning_without_verification')} {' '}
          {t('if_you_dont_receive_an_email_from_us_please_check_junk')}
        </Typography>
        <Button
          variant="regular"
          color="secondary"
          sx={{
            mb: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            alignSelf: 'center',
            cursor: 'pointer',
            fontSize: '0.9rem',
          }}
          onClick={handleStart}
        >
          {t('onboard')}
        </Button>
      </div>
    </BoardingWrapper>
  );
};

export default SignUpComplete;
