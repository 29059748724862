import React from 'react';
import { Button } from '@mui/material';
import '../../../assets/style/css/SpinButton.css'

const SpinButton = ({ onClick = () => { }, buttonText, disabled }) => {
    return (
        <button className={`pushable${disabled ? ' disabled' : ''}`} onClick={onClick} disabled={disabled}>
            <span className="shadow"></span>
            <span className="edge"></span>
            <span className="front">
                <span className="frontText">
                    {buttonText}
                </span>
            </span>
        </button>
    );
};

export default SpinButton;