import { React, useState, useEffect } from 'react';
import { Grid } from '@mui/material';

import { GrammarWrapper, GrammarBox, GrammarFrontCard, GrammarCardP, GrammarCardExample, GrammarPinyin, GrammarCardTitle } from './CardStyles';
import ProgressBar from '../ProgressBar/ProgressBar.jsx';
import CardButton from '../Buttons/CardButton';

const GrammarCard = ({ lessonData, progress, onNextCard }) => {

    return (
        <>
            <GrammarWrapper>
                <GrammarBox>
                    <GrammarFrontCard>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <GrammarCardTitle>Grammar Facts 🤓</GrammarCardTitle>
                            </Grid>
                            <Grid item>
                                <GrammarCardP>{lessonData.structure.structure}</GrammarCardP>
                                <GrammarCardP>{lessonData.structure.explanation}</GrammarCardP>
                            </Grid>
                            <Grid item>
                                <GrammarCardExample>Example</GrammarCardExample>
                                <GrammarCardP>{lessonData.structure.example}</GrammarCardP>
                                <GrammarPinyin>{lessonData.structure.pinyin}</GrammarPinyin>
                                <GrammarCardP>{lessonData.structure.translation}</GrammarCardP>
                            </Grid>
                        </Grid>
                    </GrammarFrontCard>
                </GrammarBox>
                <ProgressBar progress={progress} />
                <CardButton
                    onClick={() => onNextCard()}
                    text={'Next'}
                    color={'#5854EC'}
                    hcolor={'#5854EC'}
                    bcolor={'#5854EC'}
                />
            </GrammarWrapper>
        </>
    );
}

export default GrammarCard;