import React from 'react';
import { Button, Typography } from '@mui/material';
import ConditionalTooltip from '../Tooltip/ConditionalTooltip';


const Stage1 = ({
  languageScales,
  selectedLanguageId,
  handleScaleSelection,
}) => {
  return (
    <>
      {languageScales[selectedLanguageId]?.map((ls) => (
        <ConditionalTooltip
          key={ls.id}
          title={
            <span style={{ fontSize: '16px' }}>
              We do not support {ls.index} language level yet, sorry, but we
              promise to become better ASAP
            </span>
          }
          renderTooltip={!ls.isEnabled}
        >
          <span>
            <Button
              key={ls.id}
              variant="langlevel"
              sx={{
                mb: 1,
                opacity: ls.isEnabled ? 1 : 0.5,
                pointerEvents: ls.isEnabled ? 'auto' : 'none',
              }}
              onClick={() => handleScaleSelection(ls)}
            >
              <Typography variant="option" color="neutral.700">
                {ls.index}
              </Typography>
              <Typography variant="comment" color="neutral.600">
                {ls.name}
              </Typography>
            </Button>
          </span>
        </ConditionalTooltip>
      ))}
    </>
  );
};

export default Stage1;
