import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Button, Typography, Box } from '@mui/material';

import { playMahjong } from '../../../redux/slices/games';
import { fetchUser } from '../../../redux/slices/user';

import MahjongCard from './MahjongCard';
import BackToDashboardButton from '../../Common/Buttons/BackToDashboardButton';


const GAME_STATES = {
    NOT_STARTED: 'NOT_STARTED',
    FIRST_DRAW: 'FIRST_DRAW',
    GAME_OVER: 'GAME_OVER',
};

const ChineseMahjong = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [gameState, setGameState] = useState(GAME_STATES.NOT_STARTED);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const uid = useSelector((state) => state.user.userFromFirebase?.uid);
    const userFromDb = useSelector((state) => state.user.user);
    const { house, user, result, payout } = useSelector((state) => state.games.games.chineseMahjong);

    const fullName = userFromDb ? `${userFromDb.firstName} ${userFromDb.lastName}` : 'Anonymous';

    useEffect(() => {
        if (!userFromDb && uid) {
            dispatch(fetchUser(uid));
        }
    }, []);

    useEffect(() => {
        if (result) {
            alert(`${result}. Payout: ${payout}`);
        }
    }, [result]);

    const handleNavigateToDashboard = () => {
        navigate('/dashboard');
    };

    const handleDraw = () => {
        dispatch(playMahjong({ action: 'DRAW' }));
        setGameState(GAME_STATES.FIRST_DRAW);
    };

    const handleSelection = (index) => () => {
        if (gameState === GAME_STATES.FIRST_DRAW) {
            setSelectedIndex(index);
        }
    };

    const handleReplace = () => {
        dispatch(playMahjong({ action: 'REPLACE', card: selectedIndex }));
        setGameState(GAME_STATES.GAME_OVER);
    }

    return (
        <>
            <BackToDashboardButton onClick={handleNavigateToDashboard} />
            <Stack direction="column" gap={2}>
                <Stack direction="row" gap={2} sx={{ position: 'relative', mb: 8 }}>
                    <Typography sx={{ fontSize: '1.3rem', color: 'white', position: 'absolute', top: '-20%' }}>{t('house_of_satspanda')}</Typography>
                    {house?.map((c, index) => (
                        <MahjongCard suite={c.suite} rank={c.rank} size="s" key={`hgc-${index}`} />
                    ))}
                </Stack>
                <Stack direction="row" gap={2} sx={{ position: 'relative' }}>
                    <Typography sx={{ fontSize: '1.3rem', color: 'white', position: 'absolute', top: '-20%' }}>{fullName}</Typography>
                    {user?.map((c, index) => (
                        <MahjongCard
                            suite={c.suite}
                            rank={c.rank}
                            size="s"
                            onClick={handleSelection(index)}
                            selected={index === selectedIndex}
                            key={`ugc-${index}`}
                        />
                    ))}
                </Stack>
                <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 4 }}>
                    {gameState === GAME_STATES.NOT_STARTED && (<Button variant="game" sx={{ width: '50%' }} onClick={handleDraw}>{t('draw')}</Button>)}
                    {gameState === GAME_STATES.FIRST_DRAW && (
                        <Button
                            variant="game"
                            sx={{ width: '50%' }}
                            disabled={selectedIndex === null}
                            onClick={handleReplace}
                        >
                            {t('replace')}
                        </Button>
                    )}
                </Stack>

            </Stack>
        </>
    );
};

export default ChineseMahjong;