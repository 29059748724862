import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { Toolbar, Box, Tooltip, Icon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import LogoutIcon from '@mui/icons-material/Logout';
import Wifi from '@mui/icons-material/Wifi';
import WifiOff from '@mui/icons-material/WifiOff';

import { cleanLoggedUser } from '../../redux/slices/user';

import logo from '../../assets/images/logo/satspanda.png';
import LanguageSelectionModal from '../../ui-components/layout/LanguageSelectionModal';
import { fetchLanguages } from '../../redux/slices/language';

const LOCALE_ICONS = {
  en: '🇺🇸',
  ru: '🇷🇺',
  zh: '🇨🇳',
  tr: '🇹🇷',
}

function StyledIconButton({ title, icon, onClick, component, to, disabled = false }) {
  const location = useLocation();
  const selected = location.pathname === to;

  return (
    <Box>
      <Tooltip title={title} placement="right">
        <span>
          <IconButton
            size="large"
            color="black"
            aria-label="menu"
            sx={{
              mt: 2, borderRadius: '8px',
              ...selected && {
                borderRadius: '8px',
                backgroundColor: 'primary.main',
                transition: 'background-color 0.5s ease-in-out',
              },
              '&:hover': {
                borderRadius: '8px',
                backgroundColor: 'primary.main',
                transition: 'background-color 0.5s ease-in-out',
              },
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            }}
            onClick={onClick}
            component={component}
            to={to}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
};

const VerticalAppbar = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [isOnline, setIsOnline] = useState(true);
  const [langSelectionOpen, setLangSelectionOpen] = useState(false);

  const { languages } = useSelector((state) => state.languages)

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    }
  });

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchLanguages())
    }
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem('satspanda:id_token');
        dispatch(cleanLoggedUser());
        navigate('/signin');
      });
  };

  const handleLanguageSelected = (language) => {
    i18n.changeLanguage(language.locale);
    setLangSelectionOpen(false);
    localStorage.setItem('satspanda:id_language', language.locale);
  }

  return (
    <>
      <Toolbar
        sx={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '82px',
          height: '100%',
          backgroundColor: 'dark.main',
          '&.MuiToolbar-root': {
            padding: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center' }}>
          <Box sx={{ mt: 4, bgcolor: 'primary.main', height: '48px', width: '48px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo} height="43px" width="43px" />
          </Box>
          <StyledIconButton
            title={t('Dashboard')}
            icon={<GridViewOutlinedIcon className="icon" />}
            component={Link}
            to="/dashboard"
          />
          <StyledIconButton
            title={t('Learning')}
            icon={<MenuBookIcon className="icon" />}
            selected={true}
            component={Link}
            to="/learning"
          />
          <StyledIconButton
            title={t('Balance')}
            icon={<CurrencyBitcoinIcon className="icon" />}
            component={Link}
            to={'/balance'}
          />
          <StyledIconButton
            title={t('Profile')}
            icon={<PersonIcon className="icon" />}
            component={Link}
            to={'/profile'}
          />
        </Box>
        <Box sx={{ marginTop: 'auto', marginBottom: 3 }}>
          {isOnline && (
            <Tooltip title={t('connected_to_satspanda_servers')} placement="right">
              <span>
                <StyledIconButton
                  icon={<Wifi className="icon" />}
                  disabled={true}
                />
              </span>
            </Tooltip>
          )}
          {!isOnline && (
            <Tooltip title={t('disconnected_from_satspanda_servers_functionality_may_be_degraded')} placement="right">
              <span>
                <StyledIconButton
                  icon={<WifiOff className="icon" />}
                  disabled={true}
                />
              </span>
            </Tooltip>
          )}
          <StyledIconButton
            title={t('Language')}
            icon={<Icon className="icon">{LOCALE_ICONS[i18n.language]}</Icon>}
            onClick={() => setLangSelectionOpen(true)}
          />
          <StyledIconButton
            title={t('Logout')}
            icon={<LogoutIcon className="icon" />}
            onClick={() => handleLogout()}
          />
        </Box>
      </Toolbar>
      <LanguageSelectionModal
        open={langSelectionOpen}
        onClose={() => setLangSelectionOpen(false)}
        onLanguageSelected={handleLanguageSelected}
      />
    </>
  );
};

export default VerticalAppbar;
