import React from 'react';
import { Paper, Grid } from '@mui/material';


const GamesPanel = ({ children }) => {
    return (
        <Paper
            sx={{ overflow: 'auto', boxShadow: 'none', height: '80%' }}
        >
            {children}
        </Paper>
    );
};

export default GamesPanel;