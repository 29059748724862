import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Wheel } from 'react-custom-roulette';
import dayjs from 'dayjs';

import { playRussianRoulette } from '../../../redux/slices/games';
import { fetchUser } from '../../../redux/slices/user';

import SpinButton from '../Buttons/SpinButton';
import BackToDashboardButton from '../../Common/Buttons/BackToDashboardButton';


const data = [
    { option: '0', payout: 0, style: { backgroundColor: '#fff', textColor: 'black' } },
    { option: '5', payout: 5, style: { backgroundColor: '#fff', textColor: 'black' } },
    { option: '10', payout: 10, style: { backgroundColor: '#fff', textColor: 'black' } },
    { option: '15', payout: 15, style: { backgroundColor: '#fff', textColor: 'black' } },
    { option: '20', payout: 20, style: { backgroundColor: '#fff', textColor: 'black' } },
    { option: '😿', payout: -10, style: { backgroundColor: '#fff', textColor: 'black' } },
]

const RussianRoulette = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);
    const uid = useSelector((state) => state.user.userFromFirebase?.uid);
    const { payout } = useSelector((state) => state.games.games.russianRoulette);


    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [isPlayable, setIsPlayable] = useState(false);

    useEffect(() => {
        if (uid) {
            dispatch(fetchUser(uid));
        }
    }, [uid]);

    useEffect(() => {
        if (payout) {
            const prizeNum = data.findIndex((d) => d.payout === payout);
            setPrizeNumber(prizeNum);
            setMustSpin(true);
        }
    }, [payout]);

    useEffect(() => {
        const playedAt = user?.lastPlayed?.RUSSIAN_ROULETTE;
        if (playedAt) {
            const now = dayjs();
            const nowLess12Hours = now.subtract(12, 'hours');
            const lastPlayed = dayjs(playedAt);
            if (lastPlayed.isBefore(nowLess12Hours)) {
                setIsPlayable(true);
            }
        }
    }, [user]);

    const handleSpinClick = () => {
        if (!mustSpin && isPlayable) {
            dispatch(playRussianRoulette());
            setIsPlayable(false);
        }
    }

    const handleStopSpinning = () => {
        setMustSpin(false);
        // TODO: @Alena display pop up with winning sats in 'payout'
    };

    const handleNavigateToDashboard = () => {
        navigate('/dashboard');
    };

    const wheelContainerStyle = {
        position: 'relative',
    };

    const buttonStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80px',
        height: '80px',
        zIndex: 100,
    };

    return (
        <>
            <BackToDashboardButton onClick={handleNavigateToDashboard} />
            <div style={wheelContainerStyle}>
                <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    backgroundColors={['#3e3e3e', '#44d0d8']}
                    radiusLineWidth={7}
                    radiusLineColor={'#c6cfff'}
                    outerBorderColor={'#7e8ee6'}
                    outerBorderWidth={8}
                    textColors={['#ffffff']}
                    onStopSpinning={handleStopSpinning}
                />
                {/* <Button style={buttonStyle} onClick={handleSpinClick} disabled={!isPlayable} >SPIN</Button> */}
                <div style={buttonStyle}>
                    <SpinButton
                        onClick={handleSpinClick}
                        buttonText={'START'}
                        disabled={!isPlayable}
                    />
                </div>
            </div>


        </>
    )
}

export default RussianRoulette;