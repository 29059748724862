import React, { useRef, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const RunningBalanceLine = ({ runningBalance = [] }) => {
    const chartRef = useRef(null);

    const xAxis = runningBalance.map((rb) => dayjs(rb.week).format('DD MMM'));
    const yAxis = runningBalance.map((rb) => rb.running_total);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                fill: false,
            },
        ],
    });

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x',
        elements: {
            line: {
                tension: 0.3,
                pointStyle: false,
                borderColor: 'rgba(255, 255, 255, 1)',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                fill: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                color: '#FFF',
                anchor: 'end',
                formatter: (value, context) => {
                    return context.dataIndex % 3 === 0 ? value : null;
                },
            },
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: true,
                },
            },
            y: {
                display: false,
                grid: {
                    display: true,
                },
            }
        }
    };

    useEffect(() => {
        setChartData({
            ...chartData,
            labels: xAxis,
            datasets: [
                {
                    label: 'Running balance',
                    data: yAxis,
                }
            ],
        });
    }, [runningBalance]);

    return (
        <div >
            <Line ref={chartRef} data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default RunningBalanceLine;