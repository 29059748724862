import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user.js';
import languageReducer from './slices/language.js';
import journeyReducer from './slices/journey.js';
import marketDataReducer from './slices/market-data.js';
import leaderboardReducer from './slices/leaderboard.js';
import gamesReducer from './slices/games.js';
import lessonReducer from './slices/lessons.js';
import invitesReducer from './slices/invites.js';

const store = configureStore({
  reducer: {
    user: userReducer,
    languages: languageReducer,
    journeys: journeyReducer,
    marketData: marketDataReducer,
    leaderboard: leaderboardReducer,
    games: gamesReducer,
    lessons: lessonReducer,
    invites: invitesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;