import React from 'react';
import Box from '@mui/material/Box';

const LessonWrapper = ({ children }) => {
    const wrapperStyles = {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#3f40b5',
    };

    return <Box style={wrapperStyles}>{children}</Box>;
};

export default LessonWrapper;