import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Button, Skeleton, Box } from '@mui/material';

import RunningBalanceLine from '../Charts/RunningBalanceLine';


const BalancePanel = ({ balance, runningBalance, onClick, stats }) => {
  const { t } = useTranslation();

  const typographyStyles = {
    textAlign: 'right',
    fontSize: '36px',
    fontWeight: 'bold',
    color: 'white'
  };

  return (
    <>
      <Grid container sx={{ height: 'calc(100% - 27px)' }}>
        <Grid item sm={9} md={10} lg={9} xl={10}>
          <RunningBalanceLine runningBalance={runningBalance} />
        </Grid>
        <Grid item sm={3} md={2} lg={3} xl={2}>
          {stats === 'idle' ? (
            <Typography sx={typographyStyles}>
              {balance}<span style={{ fontSize: '36px', fontWeight: 'normal' }}>⚡️</span>
            </Typography>
          ) : (
            <Skeleton variant="text" sx={typographyStyles} />
          )}
          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <Button variant="primary-small" onClick={onClick}>{t('check')}</Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BalancePanel;
