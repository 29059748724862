import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from 'react-redux';

const LeaderboardTable = ({ topEarners, onSend = () => { } }) => {
  const { t } = useTranslation();

  const uuid = useSelector((state) => state.user.userFromFirebase?.uid);
  const prehandleSend = (topearner) => () => {
    onSend(topearner);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('rank')}</TableCell>
            <TableCell>{t('user')}</TableCell>
            <TableCell align="right">{t('sats')}</TableCell>
            <TableCell align="right" width={50}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topEarners?.map((topearner, index) => (
            <TableRow key={topearner.uuid}>
              <TableCell>
                <Typography>
                  {index === 0 && '🥇'}
                  {index === 1 && '🥈'}
                  {index === 2 && '🥉'}
                  {index > 2 && index + 1}
                </Typography>
              </TableCell>
              <TableCell>{`${topearner.firstname ? topearner.firstname : 'Anonymous'}${topearner.uuid === uuid ? ` (${t('you')})` : ''}`}</TableCell>
              <TableCell align="right">
                <Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  ⚡️{topearner.total_earnings} sat
                </Typography>
              </TableCell>
              <TableCell align="right">
                {topearner.uuid !== uuid && (
                  <Tooltip title={`Send to user ${topearner.uuid}`} enterDelay={1000}>
                    <IconButton size="small" onClick={prehandleSend(topearner)}>
                      <SendIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeaderboardTable;
