import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, signInAnonymously, GoogleAuthProvider } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Link,
    Box,
    Typography,
    Divider,
} from '@mui/material';

import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import GoogleIcon from '@mui/icons-material/Google';

import Checklist from './Checklist';

export default function FooterBelowOr({ children }) {
    const auth = getAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSignInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        signInWithPopup(auth, provider)
            .then((result) => {
                // // This gives you a Google Access Token. You can use it to access the Google API.
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;
                // // The signed-in user info.
                // const user = result.user;
                // // IdP data available using getAdditionalUserInfo(result)
                // // ...
                navigate('/');
            })
            .catch((error) => {
                // display error
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // const email = error.customData.email;
                // const credential = GoogleAuthProvider.credentialFromError(error);
            });
    };

    const handleSignInAnonymously = () => {
        signInAnonymously(auth).then(() => {
            navigate('/onboarding');
        });
    };

    return (
        <Box>
            <Divider orientation="horizontal" flexItem>
                {t('or')}
            </Divider>
            <Box>
                <Button
                    startIcon={<GoogleIcon />}
                    fullWidth
                    variant="signin-outline"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSignInWithGoogle}
                >
                    {t('continue_with_google')}
                </Button>
                <Button
                    startIcon={<NoAccountsIcon />}
                    fullWidth
                    variant="signin-outline"
                    sx={{ mb: 1 }}
                    onClick={handleSignInAnonymously}
                >
                    {t('continue_anonymously')}
                </Button>
            </Box>
            {children}
            <Box sx={{ mb: 1 }}>
                <Checklist />
            </Box>
            <Box sx={{ mb: 2 }}>
                <Typography color="neutral.500" variant="font5">
                    {t('new_accounts_are_subject_to_our')}{' '}
                    <Link href="https://satspanda.com/tos.html" color="neutral.500">
                        {t('terms_and_conditions')}
                    </Link>{' '}
                    {t('and')}{' '}
                    <Link
                        href="https://satspanda.com/privacy.html"
                        color="neutral.500"
                    >
                        {t('privacy_policy')}.
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

