import React from 'react';
import { Paper } from '@mui/material';
import cards from '../cards';

const GameCard = ({ suite, rank, ...props }) => {
    const name = suite === 'b' ? '_b' : `_${rank}${suite}`;

    const src = cards[name] ? cards[name] : cards._b;

    return (
        <img src={src} {...props} width="160px" height="auto" />
    )
};

export default GameCard;