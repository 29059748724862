import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const ModuleBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '14px 18px',
    gap: '4px',
  });

export const StyledLockIcon = styled(LockIcon)({
    position: 'absolute',
    top: -16,
    left: 0,
    width: '18px',
    height: '18px',
    color: '#4B5563',
  });
  
export const StyledLockOpenIcon = styled(LockOpenIcon)({
    position: 'absolute',
    top: -16,
    left: 0,
    width: '18px',
    height: '18px',
    color: '#5854ec',
  });