import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const BaseCardWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: theme.palette.background.cardWrapper, 
  borderRadius: 8,
  padding: 32,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
}));

export const BaseCardBox = styled('div')({
  backgroundColor: '#ffff',
  borderRadius: 8,
  margin: '0 auto',
  transformStyle: 'preserve-3d',
  transition: 'all 0.8s ease',
});



