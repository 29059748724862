import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const AuthWrapper = ({ children, display }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      component={Paper}
      square
      sx={{
        px: { xxl: 25, xl: 20, lg: 15, md: 10, sm: 15 },
        py: { xl: 5, lg: 5, md: 0 },
        display: display,
      }}
    >
      {children}
    </Grid>
  );
};

export default AuthWrapper;


