import React from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const DashboardContainer = ({ children, alignItems, justifyContent, height, minHeight, display }) => {
  const theme = useTheme();

  const styles = {
    [theme.breakpoints.up('xs')]: {
      padding: '1rem 1rem',
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 2rem',
    },
    [theme.breakpoints.up('md')]: {
      padding: '1rem 2rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem 3rem',
    },
    alignItems: alignItems,
    justifyContent: justifyContent || null,
    height: height || '100%',
    minHeight: minHeight,
    display: display,
    boxSizing: 'border-box'
  };

  return (
    <Grid container sx={styles}>
      {children}
    </Grid>
  );
};

export default DashboardContainer;
