import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, Button, Typography, Box } from '@mui/material';

const LessonCard = ({ journeyId, lesson, index, disabled }) => {
    const navigate = useNavigate();

    const handleStart = () => {
        navigate(`/lessons/${lesson.lessonId}`, { state: { journeyId } })
    };

    console.log(lesson.lessonId);

    return (
        <Card sx={{
            width: 500,
            height: 110,
            borderRadius: '12px',
            px: 3,
            py: 2
        }}>
            <CardContent>
                <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>Lesson {index + 1}: {lesson?.Lesson?.name}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button variant="small" onClick={handleStart} sx={{
                        opacity: disabled ? 0.5 : 1,
                        pointerEvents: disabled ? 'none' : 'pointer',
                    }}>Start</Button>
                </Box>

            </CardContent>
        </Card>
    );
};

export default LessonCard;