import React from 'react';
import { Grid, Divider, Skeleton, Card } from '@mui/material';

const JourneyCardSkeleton = () => {
    const skeletonItems = Array.from({ length: 3 }, (_, index) => (
        <Grid item md={6} lg={4} key={`skeleton-${index}`}>
            <Skeleton variant="rectangular" nimation="wave" sx={{ width: 345, height: 240, borderRadius: '12px', py: 1 }} />
        </Grid>
    ));

    return (
        <>
            <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mb: 3, color: 'neutral.500', fontSize: '14px' }}>In progress</Divider>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={1} sx={{ maxWidth: { md: '900px', lg: '1200px' } }}>
                        {skeletonItems}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3, mb: 3, color: 'neutral.500', fontSize: '14px' }}>
                <Divider textAlign="left">Finished</Divider>
            </Grid>
        </>
    );
};

export default JourneyCardSkeleton;