import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { fetchLesson, submitLesson } from '../../redux/slices/lessons';

import LessonWrapper from '../../ui-components/Lessons/Container/LessonWrapper';
import LessonSummary from './LessonSummary';
import TurnCard from '../../ui-components/Lessons/WrapperCards/TurnCard';
import BackToDashboardButton from '../../ui-components/Common/Buttons/BackToDashboardButton';
import WordOptionsCard from '../../ui-components/Lessons/WrapperCards/WordOptionsCard';
import AcronymTest from '../../ui-components/Lessons/WrapperCards/AcronymTest';
import PhraseOptionsCard from '../../ui-components/Lessons/WrapperCards/PhraseOptionsCard';
import GrammarCard from '../../ui-components/Lessons/WrapperCards/GrammarCard';
import LessonCompleted from '../../ui-components/Lessons/Box/LessonCompleted';


const Lessons = ({ }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { journeyId } = useLocation().state;
    const { lessonId } = useParams();

    const [cardIndex, setCardIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [isQuizOver, setIsQuizOver] = useState(false);

    const { lesson, status } = useSelector((state) => ({
        lesson: state.lessons.lesson,
        status: state.lessons.status,
    }));

    useEffect(() => {
        if (lessonId) {
            dispatch(fetchLesson(lessonId));
        }
    }, [lessonId]);

    useEffect(() => {
        if (cardIndex > lessonData?.length - 1) {
            dispatch(submitLesson({ lessonId, answers }))
                .then(() => {
                    setIsQuizOver(true);
                    console.log('this quiz is over');
                });
        }
    }, [cardIndex]);


    const lessonData = lesson?.LessonItems;
    const progress = ((cardIndex + 1) / lessonData?.length) * 100;

    const handleNextCard = (clickedButton) => {
        setTimeout(() => {
            setAnswers((prevAnswers) => ([
                ...prevAnswers,
                {
                    id: lessonData[cardIndex].id,
                    lessonId: lessonData[cardIndex].lessonId,
                    clickedButton,
                    givenAt: dayjs().toISOString(),
                },
            ]));
            setCardIndex((prevIndex) => prevIndex + 1);
        }, 200);
    };

    const renderTurnCard = () => (
        <TurnCard
            progress={progress}
            lessonData={lessonData[cardIndex]}
            onNextCard={handleNextCard}
        />
    );

    const renderAcronymTest = () => (
        <AcronymTest
            lessonData={lessonData[cardIndex]}
            onNextCard={handleNextCard}
            status={status}
        />
    );

    const renderWordOptionsCard = () => (
        <WordOptionsCard
            progress={progress}
            lessonData={lessonData[cardIndex]}
            onNextCard={handleNextCard}
        />
    );

    const renderPhraseOptionsCard = () => (
        <div>
            <PhraseOptionsCard
                progress={progress}
                lessonData={lessonData[cardIndex]}
                onNextCard={handleNextCard}
            />
        </div>
    );

    const renderGrammarCard = () => (
        <div>
            <GrammarCard
                progress={progress}
                lessonData={lessonData[cardIndex]}
                onNextCard={handleNextCard}
            />
        </div>
    );

    const renderDefault = () => (
        <div>
        </div>
    );

    const getRenderedCard = (cardType) => {
        switch (cardType) {
            case 'PhraseOptionsCard':
                return renderPhraseOptionsCard();
            case 'WordOptionsCard':
                return renderWordOptionsCard();
            case 'TurnCard':
                return renderTurnCard();
            case 'AcronymTest':
                return renderAcronymTest();
            case 'SentenceTestCard':
                return renderAcronymTest();
            case 'GrammarCard':
                return renderGrammarCard();
            default:
                return renderDefault();
        }
    };

    const handleNavigateToLearning = () => {
        navigate('/learning');
    };


    return (
        <LessonWrapper>
            <BackToDashboardButton onClick={handleNavigateToLearning} />
            {status === 'loading' ? (
                <p>Loading...</p>
            ) : (
                getRenderedCard(lessonData?.[cardIndex]?.type)
            )}
            {isQuizOver && <LessonCompleted journeyId={journeyId} sats={5} score={85} />}
        </LessonWrapper>
    );
};

export default Lessons;