import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const fetchLanguages = createAsyncThunk('languages/fetchLanguages', async () => {
    const response = await client('/v1/languages', { method: 'get' });
    return response.data;
});

export const fetchLanguageScales = createAsyncThunk('languages/fetchLanguageScales', async (languageId) => {
    const response = await client(`/v1/languages/${languageId}/scales`, { method: 'get' });
    return response.data;
});

export const updateUserPortfolio = createAsyncThunk('languages/updateUserPortfolio', async ({ uuid, scale, language }) => {
    const response = await client(`/v1/users/${uuid}/portfolios`, {
        method: 'put', body: {
            scale,
            language,
        }
    });
    return response.data;
});

const INITIAL_STATE = {
    status: {
        languages: 'idle',
        scales: 'idle',
        update: 'idle',
    },
    languages: [],
    languageScales: {},
};

const languagesSlice = createSlice({
    name: 'languages',
    initialState: INITIAL_STATE,
    extraReducers: (builder) =>
        builder
            .addCase(fetchLanguages.pending, (state, action) => {
                state.status.languages = 'loading';
            })
            .addCase(fetchLanguages.fulfilled, (state, action) => {
                state.status.languages = 'idle';
                state.languages = action.payload;
            })
            .addCase(fetchLanguages.rejected, (state, action) => {
                state.status.languages = 'idle';
            })
            .addCase(fetchLanguageScales.pending, (state, action) => {
                state.status.scales = 'loading';
            })
            .addCase(fetchLanguageScales.fulfilled, (state, action) => {
                state.status.scales = 'idle';
                state.languageScales = {
                    ...state.languageScales,
                    [action.meta.arg]: [
                        ...action.payload,
                    ]
                };
            })
            .addCase(fetchLanguageScales.rejected, (state, action) => {
                state.status.scales = 'idle';
            })
});


// export const { } = languagesSlice.actions;

export default languagesSlice.reducer;