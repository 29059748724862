import React from 'react';
import { Box } from '@mui/material';

const GamesWrapper = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                bgColor: '#5854ec',
                backgroundImage: 'linear-gradient(0deg, #5854ec 0%, #080404 100%)',
                p: 6,
                position: 'relative'
            }}
        >
            {children}
        </Box>
    );
}

export default GamesWrapper;