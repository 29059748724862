import * as React from 'react';
import { Box } from '@mui/material';
import AnimatedLogo from './AnimatedLogo';

const Preloader = () => {
  return (
    <>
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AnimatedLogo></AnimatedLogo>
      </Box>
    </>
  );
};

export default Preloader;
