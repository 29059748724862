import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';


const Checklist = () => {
  const { t } = useTranslation();
  return (
    <List>
      <ListItem sx={{ py: 0, px: 0 }}>
        <CheckIcon sx={{ fontSize: '0.8rem', marginRight: '0.5rem' }} color="success" />
        <ListItemText
          primary={<Typography variant="font5" color="neutral.500">{t('founded_eu')}</Typography>}
        />
      </ListItem>
      <ListItem sx={{ py: 0, px: 0 }}>
        <CheckIcon sx={{ fontSize: '0.8rem', marginRight: '0.5rem' }} color="success" />
        <ListItemText
          primary={<Typography variant="font5" color="neutral.500">{t('mimimal_withdrawal')}</Typography>}
        />
      </ListItem>
      <ListItem sx={{ py: 0, px: 0 }}>
        <CheckIcon sx={{ fontSize: '0.8rem', marginRight: '0.5rem' }} color="success" />
        <ListItemText
          primary={<Typography variant="font5" color="neutral.500">{t('clear_monetization')}</Typography>}
        />
      </ListItem>
    </List>
  );
};

export default Checklist;
