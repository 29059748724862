import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const inviteFriends = createAsyncThunk('invites/friends', async (invitesArray) => {
    const response = await client('/v1/invites', {
        method: 'post',
        body: [...invitesArray],
    });
    return response.data;
});

export const fetchInvite = createAsyncThunk('invites/invite', async (inviteId) => {
    const response = await client(`/v1/invites/${inviteId}`, {
        method: 'get',
    });
    return response.data;
});

const INITIAL_STATE = {
    status: 'idle',
    invite: null,
};

const invitesSlice = createSlice({
    name: 'invites',
    initialState: INITIAL_STATE,
    extraReducers: (builder) =>
        builder
            .addCase(inviteFriends.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(inviteFriends.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(inviteFriends.rejected, (state, action) => {
                state.status = 'idle';
            })
            .addCase(fetchInvite.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchInvite.fulfilled, (state, action) => {
                state.status = 'idle';
                state.invite = action.payload;
            })
            .addCase(fetchInvite.rejected, (state, action) => {
                state.status = 'idle';
            })
});


export default invitesSlice.reducer;