import React from 'react';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SendIcon from '@mui/icons-material/Send';
import AddCardIcon from '@mui/icons-material/AddCard';

import SpreadButton from '../../Common/Buttons/SpreadButton';
import RunningBalanceLine from '../../Dashboard/Charts/RunningBalanceLine';

const BitcoinBalance = ({
  balance,
  btcusd,
  runningBalance,
  onSend = () => { },
  onTopUp = () => { },
}) => {
  const { t } = useTranslation();

  const balanceUsd = numeral(balance / btcusd * 10).format('0,0.00');

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ height: '100%', position: 'relative' }}
    >
      <Grid item xs={9}>
        <RunningBalanceLine runningBalance={runningBalance} />
      </Grid>
      <Grid item xs={3}>
        <Stack sx={{ alignItems: { xs: 'center', md: 'flex-end' } }}>
          <Stack>
            <Typography sx={{
              fontSize: '36px',
              fontWeight: 'bold',
              color: 'white'
            }}>
              {balance}
              <span style={{ fontSize: '36px', fontWeight: 'normal' }}>{'⚡️'}</span>
            </Typography>
            <Typography sx={{
              fontSize: '16px',
              color: 'white'
            }}>
              {balanceUsd} USD
            </Typography>
          </Stack>
        </Stack>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '40%',
          transform: 'translateX(-50%)',
        }}
      >
        <SpreadButton
          text={t('top_up')}
          icon={<AddCardIcon fontSize="medium" className="icon" />}
          onClick={onTopUp}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: '40%',
          transform: 'translateX(-50%)',
        }}
      >
        <SpreadButton
          text={t('send')}
          icon={<SendIcon fontSize="medium" className="icon" />}
          onClick={onSend}
        />
      </Box>


    </Grid>
  );
};

export default BitcoinBalance;
