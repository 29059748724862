import React, { useState } from 'react';

import { Box, Typography, IconButton } from '@mui/material';

const SpreadButton = ({ onClick = () => { }, icon, text }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: -50,
                left: '50%',
                transform: 'translateX(-50%)',
            }}
        >
            <IconButton onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                    mt: 2,
                    width: isHovered ? '125px' : 'auto',
                    height: 'auto',
                    bgcolor: 'dark.main',
                    color: 'white',
                    borderRadius: '50%',
                    position: 'relative',
                    '&:hover': {
                        bgcolor: 'primary.main',
                        color: 'white',
                        transition: '0.5s ease',
                        borderRadius: '12px',
                    },
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {icon}
                    {isHovered && (
                        <Typography
                            variant="font5"
                            sx={{
                                ml: '8px',
                            }}
                        >
                            {text}
                        </Typography>
                    )}
                </Box>
            </IconButton>
        </Box>
    )
};

export default SpreadButton;