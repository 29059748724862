import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    Avatar,
    TextField,
    Stack,
    Paper,
    Box,
    Typography,
    Modal,
    ClickAwayListener,
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
    Button,
} from '@mui/material';


import panda from '../../assets/images/logo/satspanda.png';
import ConditionalTooltip from '../Onboarding/Tooltip/ConditionalTooltip';


const LanguageSelectionModal = ({ open, onLanguageSelected = () => { }, onClose = () => { } }) => {
    const { t } = useTranslation();

    const { languages } = useSelector((state) => state.languages);

    const prehandleClose = () => {
        onClose();
    };

    const prehandleLanguageSelection = (language) => () => {
        onLanguageSelected(language);
    };

    return (
        <Modal open={open} onClose={prehandleClose}>
            <Box>
                <Paper
                    sx={{
                        width: { xs: '340px', sm: '500px' },
                        borderRadius: '12px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            px: 3,
                            py: 2,
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: 'primary.main',
                                width: 56,
                                height: 56,
                                m: '15px auto',
                            }}
                        >
                            <img
                                src={panda}
                                alt="panda-logo"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Avatar>
                        <Typography
                            id="modal-modal-title"
                            variant="font4"
                            sx={{ textAlign: 'center' }}
                        >
                            {t('select_your_base_interface_language')}
                        </Typography>
                        <Typography>
                            {t('note_some_journeys_only_in_certain_languages')}
                        </Typography>
                        <Box flex="flex-wrap" sx={{ margin: 2 }}>
                            {languages?.map((l, index) => (
                                <ConditionalTooltip
                                    renderTooltip={!l.isEnabled}
                                    title={
                                        <span style={{ fontSize: '16px' }}>
                                            {l.sorry} We do not support {l.name}{' '}
                                            {l.emoji} yet, but our team of well-trained developers is
                                            working hard to get it fixed ASAP.
                                        </span>
                                    }
                                    key={`ct-${index}`}
                                >
                                    <span>
                                        <Button
                                            variant="outlined"
                                            key={`l-${index}`}
                                            startIcon={l.emoji}
                                            disabled={!l.isEnabled}
                                            sx={{ margin: 1 }}
                                            onClick={prehandleLanguageSelection(l)}
                                        >
                                            {l.nativeName}
                                        </Button>
                                    </span>
                                </ConditionalTooltip>
                            ))}
                        </Box>
                    </Box>
                </Paper>
            </Box >
        </Modal >
    );
};

export default LanguageSelectionModal;