import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../configs/firebase';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserFromFirebase } from '../redux/slices/user';

import Preloader from '../ui-components/Common/Preloader/Preloader';


export const PrivateRoute = ({ redirectPath = '/signin', children }) => {
    const [user, loading] = useAuthState(auth);
    const dispatch = useDispatch();

    useEffect(() => {
        async function refreshToken() {
            if (!user) return;
            const idResult = await user.getIdTokenResult();
            localStorage.setItem('satspanda:id_token', idResult.token);
            dispatch(setUserFromFirebase(user));
        }
        refreshToken();
    }, [user, dispatch]);

    if (loading) {
        return <Preloader />;
    }
    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }
    return children;
};
