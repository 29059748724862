import { React, useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';


const ProgressBar = ({ progress, mt }) => {
  return (

    <LinearProgress
      sx={{
        height: 20,
        mb: 3,
        mt: mt,
        borderRadius: 5,
        backgroundColor: '#e0dcf4',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#5854ec',
        },
      }}
      style={{ width: '100%' }}
      variant="determinate"
      value={progress}
    ></LinearProgress>
  );
};

export default ProgressBar;
