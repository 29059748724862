import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

import {
    Avatar,
    TextField,
    Stack,
    Paper,
    Box,
    Typography,
    Modal,
    ClickAwayListener,
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import panda from '../../../assets/images/logo/satspanda.png';
import qrImg from '../../../assets/images/icons/qrcode.png';


const TopUpModal = ({ open, qrValue, onClose = () => { }, onAmountSelected = () => { }, onFinish = () => { } }) => {
    const { t } = useTranslation();

    const [selectedAmount, setSelectedAmount] = useState(null);
    const [internalOpen, setInternalOpen] = useState(open);

    useEffect(() => {
        if (!open) {
            setSelectedAmount(null);
        }
        setInternalOpen(open);
    }, [open]);

    const prehandleSelectedAmountChange = (event, newAmount) => {
        if (newAmount !== null) {
            setSelectedAmount(newAmount);
        }
        onAmountSelected(newAmount);
    };

    const prehandleClickAway = (event) => {
        setSelectedAmount(null);
        onClose(event);
    };

    const prehandleClose = () => {
        setSelectedAmount(null);
    };

    const handleCopyClick = (event) => {
        navigator.clipboard.writeText(qrValue);
    };

    return (
        <Modal open={open} onClose={prehandleClose}>
            <Box>
                <ClickAwayListener onClickAway={prehandleClickAway}>
                    <Paper
                        sx={{
                            width: { xs: '340px', sm: '500px' },
                            borderRadius: '12px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                px: 3,
                                py: 2,
                            }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: 'primary.main',
                                    width: 56,
                                    height: 56,
                                    m: '15px auto',
                                }}
                            >
                                <img
                                    src={panda}
                                    alt="panda-logo"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </Avatar>
                            <Typography
                                id="modal-modal-title"
                                variant="font4"
                                sx={{ textAlign: 'center' }}
                            >
                                {t('top_up_your_satspanda_wallet')}
                            </Typography>
                            <ToggleButtonGroup
                                value={selectedAmount}
                                onChange={prehandleSelectedAmountChange}
                                exclusive

                            >
                                <ToggleButton value="10" aria-label="left aligned">
                                    <Typography>10 ⚡️</Typography>
                                </ToggleButton>
                                <ToggleButton value="100" aria-label="left aligned">
                                    <Typography>100 ⚡️</Typography>
                                </ToggleButton>
                                <ToggleButton value="1000" aria-label="left aligned">
                                    <Typography>1000 ⚡️</Typography>
                                </ToggleButton>
                                <ToggleButton value="10000" aria-label="left aligned">
                                    <Typography>10000 ⚡️</Typography>
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {qrValue ? (
                                <Stack sx={{ mt: 2 }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                        value={qrValue}
                                        viewBox={'0 0 256 256'}
                                    />
                                    <TextField
                                        sx={{ mt: 1 }}
                                        value={qrValue}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleCopyClick}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            )
                                        }}
                                    />
                                </Stack>
                            ) : (
                                <Stack sx={{ mt: 2 }}>
                                    <Box sx={{ width: '256px', height: '256px', backgroundColor: 'neutral.200' }}>
                                        <img style={{ height: '100%', width: '100%', objectFit: 'contain' }} src={qrImg} />
                                    </Box>
                                    <TextField
                                        disabled={true}
                                        sx={{ mt: 1 }}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleCopyClick} disabled={true}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            )
                                        }}
                                    />
                                </Stack>
                            )}
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Box>
        </Modal>
    );
};

export default TopUpModal;