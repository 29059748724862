import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const playRussianRoulette = createAsyncThunk('games/russian-roulette', async () => {
    const response = await client('/v1/games/russian-roulette', {
        method: 'post',
    });
    return response.data;
});

export const playBlackjack = createAsyncThunk('games/draw-blackjack', async (action) => {
    const response = await client('/v1/games/blackjack', {
        method: 'post',
        body: {
            action,
        }
    });
    return response.data;
});

export const playMahjong = createAsyncThunk('games/mahjong', async ({ action, card }) => {
    const response = await client('/v1/games/chinese-mahjong', {
        method: 'post',
        body: {
            action,
            card,
        }
    });
    return response.data;
});

const INITIAL_STATE = {
    status: {
        russianRoulette: 'idle',
        blackjack: 'idle',
        chineseMahjong: 'idle',
    },
    games: {
        russianRoulette: {
            payout: null,
            playedAt: null,
        },
        blackjack: {
            house: [{ suite: 'b' }, { suite: 'b' }],
            user: [{ suite: 'b' }, { suite: 'b' }],
            result: null,
            payout: null,
        },
        chineseMahjong: {
            house: [{ suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }],
            user: [{ suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }, { suite: 'h' }],
            result: null,
            payout: null,
        },
    },
};

const gamesSlice = createSlice({
    name: 'games',
    initialState: INITIAL_STATE,
    extraReducers: (builder) =>
        builder
            .addCase(playRussianRoulette.pending, (state, action) => {
                state.status.russianRoulette = 'loading';
            })
            .addCase(playRussianRoulette.fulfilled, (state, action) => {
                state.status.russianRoulette = 'idle';
                state.games.russianRoulette = action.payload;
            })
            .addCase(playRussianRoulette.rejected, (state, action) => {
                state.status.russianRoulette = 'idle';
            })
            .addCase(playBlackjack.pending, (state, action) => {
                state.status.blackjack = 'loading';
            })
            .addCase(playBlackjack.fulfilled, (state, action) => {
                state.status.blackjack = 'idle';
                state.games.blackjack = action.payload;
            })
            .addCase(playBlackjack.rejected, (state, action) => {
                state.status.blackjack = 'idle';
            })
            .addCase(playMahjong.pending, (state, action) => {
                state.status.chineseMahjong = 'loading';
            })
            .addCase(playMahjong.fulfilled, (state, action) => {
                state.status.chineseMahjong = 'idle';
                state.games.chineseMahjong = action.payload;
            })
            .addCase(playMahjong.rejected, (state, action) => {
                state.status.chineseMahjong = 'idle';
            })
});


export default gamesSlice.reducer;
