import React from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import theme from './theme';

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <React.Fragment>{children}</React.Fragment>
  </MuiThemeProvider>
);

export default ThemeProvider;