import { React, useState, useEffect } from 'react';
import HighlightWords from 'react-highlight-words';
import { Grid } from '@mui/material';

import { shuffleArray } from '../shuffle';
import { PhraseOptionsWrapper, PhraseOptionsBox, PhraseOptionsFrontCard, PhraseOptionsPhrase, PhraseOptionsDescription } from './CardStyles';
import CardButton from '../Buttons/CardButton';
import ProgressBar from '../ProgressBar/ProgressBar.jsx';



const PhraseOptionsCard = ({ lessonData: { structure: { phrase, sampleSentence, synonyms, antonyms, unrelated, definition } }, progress, onNextCard = () => { } }) => {
    const buttonOptions = [...synonyms, ...antonyms, ...unrelated];

    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [answerRevealed, setAnswerRevealed] = useState(false);


    useEffect(() => {
        setShuffledOptions(shuffleArray(buttonOptions));
        setAnswerRevealed(false);
    }, [synonyms, antonyms, unrelated]);

    return (
        <>
            <PhraseOptionsWrapper>
                {buttonOptions ? (
                    <PhraseOptionsBox>
                        <PhraseOptionsFrontCard>
                            <PhraseOptionsPhrase>{phrase}</PhraseOptionsPhrase>
                            <PhraseOptionsDescription>
                                <HighlightWords
                                    searchWords={[phrase]}
                                    textToHighlight={sampleSentence}
                                    highlightStyle={{ color: 'black', backgroundColor: 'white' }}
                                />
                            </PhraseOptionsDescription>
                        </PhraseOptionsFrontCard>
                    </PhraseOptionsBox>
                ) : (
                    <PhraseOptionsBox>
                        <PhraseOptionsFrontCard>
                            <PhraseOptionsPhrase>Loading...</PhraseOptionsPhrase>
                        </PhraseOptionsFrontCard>
                    </PhraseOptionsBox>
                )}
                <ProgressBar progress={progress} mt={3} />
                <Grid container spacing={1}>
                    {shuffledOptions.map((item, index) => (
                        <Grid item xs={6} key={index}>
                            <CardButton
                                onClick={() => {
                                    onNextCard(item);
                                    setAnswerRevealed(true);
                                }}
                                text={item}
                                color={answerRevealed && synonyms.includes(item) ? '#5CEF9F' : '#44d0d8'}
                                bcolor={answerRevealed && synonyms.includes(item) ? '#5CEF9F' : '#44d0d8'}
                            ></CardButton>
                        </Grid>
                    ))}
                </Grid>
            </PhraseOptionsWrapper>
        </>
    );
};

export default PhraseOptionsCard;
