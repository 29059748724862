import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import { Grid, Box, Stack, Paper, Typography, Skeleton, Button } from '@mui/material';

import { fetchUserStats, fetchUserActivities, fetchRunningBalance, fetchUser } from '../../redux/slices/user';
import { fetchLanguages } from '../../redux/slices/language';
import { inviteFriends } from '../../redux/slices/invites';

import DashboardContainer from '../../ui-components/Common/Container/DashboardContainer';
import LanguageCoursePanel from '../../ui-components/Dashboard/Panel/LanguageCoursePanel';
import BalancePanel from '../../ui-components/Dashboard/Panel/BalancePanel';
import LatestActionsPanel from '../../ui-components/Dashboard/Panel/LatestActionsPanel';
import GamesPanel from '../../ui-components/Dashboard/Panel/GamesPanel';
import MiniGameCard from '../../ui-components/Dashboard/Cards/MiniGameCard';
import InviteFriendsModal from '../../ui-components/Dashboard/Modal/InviteFriendsModal';

import roulette from '../../assets/images/icons/roulette.png';
import ace from '../../assets/images/icons/ace.png';
import mahjong from '../../assets/images/icons/mahjong.png';

const getRandomItem = (array) => array[Math.floor(Math.random() * array.length)];

const Item = ({ children, isLoading, bgcolor, bgImage }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        height: '100%',
        borderRadius: '16px',
        backgroundColor: bgcolor || 'common.white',
        backgroundImage: bgImage || null,
        px: 4,
        py: 2,
        textAlign: 'center',
      }}
    >
      {isLoading ? <Skeleton height={'100%'} /> : children}
    </Paper>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    userFromFirebase: user,
    user: userFromDb,
    completedLessons,
    journeys,
    activities,
    runningBalance,
    status: { user: userStatus, userStats: stats },
  } = useSelector((state) => state.user);
  const satsbalance = useSelector((state) => state.user.satsbalance);
  const {
    languages,
    status: { languages: languagesStatus },
  } = useSelector((state) => state.languages);

  const [inviteOpen, setInviteOpen] = useState(false);

  useEffect(() => {
    if (user && user.uid) {
      dispatch(fetchUser(user.uid)).then(() => dispatch(fetchUserStats({ uuid: user.uid })));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchLanguages());
    }
  }, [dispatch, languages]);

  useEffect(() => {
    if (user && user.uid) {
      dispatch(fetchUserActivities(user.uid));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user.uid) {
      dispatch(fetchRunningBalance(user.uid));
    }
  }, [dispatch, user]);



  const handleCheckBalance = () => {
    navigate('/balance');
  };

  const handleInviteFriends = (invites) => {
    const invitesArray = invites.map((a) => a.email);
    dispatch(inviteFriends(invitesArray));
    setInviteOpen(false);
    enqueueSnackbar(`Invites to ${invitesArray} were dispatched`, { variant: 'success' });
  };

  const displayName = userFromDb?.firstName && userFromDb?.lastName ? `${userFromDb.firstName} ${userFromDb.lastName}` : 'Anonymous';

  return (
    <>
      <DashboardContainer height={'100vh'}>
        <Grid container item xs={12} md={12} lg={7}
          sx={{
            paddingRight: { md: 0, lg: 4 },
            marginBottom: { xs: 4, lg: 0 },
            display: 'grid',
            gridTemplateRows: '200px 270px 1fr',
            height: { lg: '100vh' },
          }}
          rowSpacing={4}
        >
          <Grid item xs={12} sx={{ height: '200px' }}>
            <Item>
              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="center"
                sx={{ height: '100%' }}
              >
                <Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>
                  {t('hello')}, {displayName}!
                </Typography>
                <Typography color="neutral.600" sx={{ fontSize: '16px' }}>
                  {t('good_to_see_you_again')}
                </Typography>
              </Stack>
            </Item>
          </Grid>
          <Grid item xs={12} md={12} sx={{ height: '270px' }}>
            <Item>
              <Typography
                sx={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold' }}
              >
                {t('my_journeys')}
              </Typography>
              <LanguageCoursePanel
                journeys={journeys.filter(journey => !journey.isCompleted)}
                languages={languages}
                languageStatus={languagesStatus}
                userStatus={userStatus}
              />
            </Item>
          </Grid>
          <Grid item xs={12} md={12} sx={{ minHeight: '400px' }}>
            <Item>
              <Typography
                sx={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold' }}
              >
                {t('latest_actions')}
              </Typography>
              <LatestActionsPanel activities={activities} />
            </Item>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12} lg={5}
          sx={{
            paddingLeft: { md: 0, lg: 2 },
            display: 'grid',
            gridTemplateRows: '270px 1fr 200px',
            height: { lg: '100vh' },
          }}
          rowSpacing={4}
        >

          <Grid item xs={12} sx={{ height: '270px' }}>
            <Item bgColor={'#080404'} bgImage={'linear-gradient(147deg, #080404 0%, #5854ec 46%, #44d0d8 100%)'}>
              <Typography
                sx={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: 'white' }}
              >
                {t('balance')}
              </Typography>
              <BalancePanel
                balance={satsbalance}
                stats={stats}
                runningBalance={runningBalance}
                onClick={handleCheckBalance}
              />
            </Item>
          </Grid>

          <Grid item xs={12} sx={{ minHeight: '400px' }}>
            <Item>
              <Typography
                sx={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold' }}
              >
                {t('free_games')}
              </Typography>
              <Typography color="neutral.600" sx={{ textAlign: 'left', fontSize: '16px' }}>
                {t('simple_yet_engaging_fun_offering_periodic_rewards')}
              </Typography>
              <GamesPanel>
                <Grid container sx={{ py: 2 }} spacing={1}>
                  <Grid item sm={12} lg={12} xl={6}>
                    <MiniGameCard
                      name={t('russian_roulette')}
                      lastPlayed={userFromDb?.lastPlayed?.RUSSIAN_ROULETTE}
                      navUrl={'/games/russian-roulette'}
                      img={roulette}
                      width="60px"
                    />
                  </Grid>
                  <Grid item sm={12} lg={12} xl={6} sx={{}}>
                    <MiniGameCard
                      name={t('american_blackjack')}
                      lastPlayed={userFromDb?.lastPlayed?.BLACKJACK}
                      navUrl={'/games/blackjack'}
                      img={ace}
                      width="58px"
                    />
                  </Grid>
                  <Grid item sm={12} lg={12} xl={6}>
                    <MiniGameCard
                      name={t('chinese_mahjong')}
                      lastPlayed={userFromDb?.lastPlayed?.CHINESE_MAHJONG}
                      navUrl={'/games/chinese-mahjong'}
                      img={mahjong}
                      width="60px"
                    />
                  </Grid>
                </Grid>
              </GamesPanel>
            </Item>
          </Grid>
          <Grid container item xs={12} sx={{ height: '200px' }}>
            <Grid item xs={3} sm={3} md={3}
              sx={{ paddingRight: { xs: 1, lg: 2 } }}
            >
              <Item>
                <Stack direction="column">
                  {stats === 'idle' ? (
                    <Typography sx={{ fontSize: '48px', fontWeight: 'bold' }}>
                      {completedLessons}
                    </Typography>
                  ) : (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '48px', fontWeight: 'bold' }}
                    />
                  )}
                  <Typography>{t('lessons_completed')}</Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={3} sm={3} md={3}
              sx={{ paddingRight: { xs: 1, lg: 2 } }}
            >
              <Item>
                <Stack direction="column">
                  {stats === 'idle' ? (
                    <Typography sx={{ fontSize: '48px', fontWeight: 'bold' }}>
                      {completedLessons}
                    </Typography>
                  ) : (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '48px', fontWeight: 'bold' }}
                    />
                  )}
                  <Typography>{t('modules_completed')}</Typography>
                </Stack>
              </Item>
            </Grid>
            <Grid item xs={6} sm={6} md={6}
              sx={{ paddingLeft: { xs: 1, lg: 2 } }}
            >
              <Item bgcolor={'#5854ec'} bgImage={'linear-gradient(0deg, #5854ec 0%, #080404 100%)'}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: '100%' }}
                >
                  <Typography variant="font4" sx={{ color: 'white' }}>
                    {t('get_credit_inviting_friends')}
                  </Typography>
                  <Button
                    variant="primary-mid"
                    sx={{ mt: 3 }}
                    onClick={() => setInviteOpen(true)}
                  >
                    {t('invite_friends')}
                  </Button>
                </Stack>
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </DashboardContainer >
      <InviteFriendsModal
        open={inviteOpen}
        onClose={() => setInviteOpen(false)}
        onSend={handleInviteFriends}
      />
    </>
  );
};

export default Dashboard;
