import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, Stack } from '@mui/material';

const chipData = [
  { label: 'today', lessDays: 1 },
  { label: '7days', lessDays: 7 },
  { label: '30days', lessDays: 30 },
  { label: '3months', lessDays: 90 },
  { label: 'all_time', lessDays: 365 },
];

const TransactionsChips = ({ onChipClicked = () => { }, defaultChip = 0 }) => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(defaultChip);

  const handleChipClick = (index) => {
    setActiveIndex(index);
    onChipClicked(chipData[index]);
  };

  const renderChips = () => {
    return chipData.map((chip, index) => (
      <Chip
        key={index}
        label={t(chip.label)}
        color="primary"
        variant={activeIndex === index ? 'filled' : 'outlined'}
        onClick={() => handleChipClick(index)}
        sx={{
          padding: { xs: 0 },
        }}
      />
    ));
  };

  return (
    <Stack direction="row" sx={{ gap: 1 }} flexWrap="wrap">
      {renderChips()}
    </Stack>
  );
};

export default TransactionsChips;
