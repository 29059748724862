import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const EarningsChart = ({ earnings }) => {
  const chartRef = useRef(null);

  const amount = earnings.slice(8).map((item) => item.total_amount);
  const timeData = earnings.slice(8).map((item) => item.week);
  const formattedTimeData = timeData.map((date) => {
    const parsedDate = dayjs(date);
    return parsedDate.format('DD MMM');
  });

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
        backgroundColor: '#5854ec',
      },
    ],
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'top',
        color: '#fff',
        formatter: (value, context) => {
          if (value >= 1) {
            return value.toString();
          } else {
            return '';
          }
        },
      },
    },
    scales: {
      y: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    setChartData({
      ...chartData,
      labels: formattedTimeData,
      datasets: [
        {
          ...chartData.datasets[0],
          data: amount,
        },
      ],
    });
  }, [earnings]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Bar ref={chartRef} data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default EarningsChart;
