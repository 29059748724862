import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Card, Collapse, CardContent, Stack, Button, Typography, Box, Divider, Skeleton } from '@mui/material';
import LessonCard from '../Cards/LessonCard';
import { ModuleBox, StyledLockIcon, StyledLockOpenIcon } from './AccordionStyles';

const ModuleCustomAccordion = ({ journeyId, journeyModule, position, disabledModule }) => {
    const { Module: { name, isCompleted } } = journeyModule;
    const totalLessons = journeyModule?.JourneyModuleLessons?.filter((jml) => jml.isCompleted)?.length;
    const completedLessons = journeyModule?.JourneyModuleLessons?.filter((jml) => !jml.isCompleted)?.length;

    const [internalOpen, setInternalOpen] = useState(false);
    const [showAllLessons, setShowAllLessons] = useState(false);
    const [unlockedLessons, setUnlockedLessons] = useState([0]);


    const toggleAccordion = () => {
        setInternalOpen(!internalOpen);

    };

    const toggleShowLessons = () => {
        setShowAllLessons(!showAllLessons);
    };

    const lessons = journeyModule?.JourneyModuleLessons;
    const visibleLessons = showAllLessons ? lessons : lessons.slice(0, 3);
    const availableLessons = lessons.length > 3;

    useEffect(() => {
        visibleLessons.forEach((lesson, index) => {
            if (lesson.isCompleted && !unlockedLessons.includes(index + 1)) {
                setUnlockedLessons([...unlockedLessons, index + 1]);
            }
        });
    }, [visibleLessons, unlockedLessons]);

    return (
        <>
            <Card sx={{
                width: 600,
                height: 130,
                borderRadius: '12px',
            }}
                onClick={toggleAccordion}
            >
                <CardContent sx={{ height: '100%' }}>
                    <ModuleBox>
                        <Typography sx={{ fontSize: '1.1rem', fontWeight: '500' }}>{`${name}`}</Typography>
                        <Typography variant="font5" color="neutral.600" sx={{ mt: '2px' }}>{totalLessons}/{completedLessons} lessons completed</Typography>
                    </ModuleBox>
                </CardContent>
            </Card>
            <Collapse in={internalOpen} timeout="auto" unmountOnExit sx={{ ml: 2 }}>
                {internalOpen && (
                    <>
                        <Stack direction="column" gap={2} sx={{ mt: 2 }}>
                            {visibleLessons
                                .filter(jml => jml)
                                .sort((a, b) => a.orderInModule - b.orderInModule)
                                .map((jml, index) => {
                                    const isUnlocked = unlockedLessons.includes(index);
                                    const disabled = !isUnlocked;
                                    return (
                                        <Stack flexDirection="row" alignItems="center" sx={{ gap: '14px', position: 'relative' }} key={`jml-${index}`}>
                                            <Divider orientation="vertical" sx={{ height: 90, ml: '8px', backgroundColor: '#D1D5DB' }} />
                                            <LessonCard journeyId={journeyId} lesson={jml} index={index} order={jml.orderInModule} disabled={disabled} />
                                            {isUnlocked ? (
                                                <StyledLockOpenIcon />
                                            ) : (
                                                <StyledLockIcon />
                                            )}
                                        </Stack>
                                    );
                                })}
                        </Stack>
                        {availableLessons ? (
                            <Button variant="text" style={{ backgroundColor: 'transparent' }} onClick={toggleShowLessons}>
                                {showAllLessons ? 'Show less' : 'Show more'}
                            </Button>
                        ) : null}
                    </>
                )}
            </Collapse>
        </>
    );
};

export default ModuleCustomAccordion;