import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BackToDashboardButton = ({ onClick }) => {
    return (
        <IconButton aria-label="clode" sx={{ position: 'absolute', top: 20, left: 20, color: 'white' }} onClick={onClick} >
            <CloseIcon sx={{ fontSize: '2rem' }} />
        </IconButton >
    );
};

export default BackToDashboardButton;