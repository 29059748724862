import React from 'react';
import { Box, Avatar } from '@mui/material';


const UserAvatar = ({ firstName, lastName, href, width, height, bgColor }) => {
    const firstLetter = (str) => str && str.charAt(0);

    const avatarStyle = {
        width: width || '80px',
        height: height || '80px',
        backgroundColor: bgColor || 'transparent',
        maxWidth: '100%',
        maxHeight: '100%',
    };


    return (
        <Box>
            {href ? (
                <Avatar sx={avatarStyle} src={href} />
            ) : (
                <Avatar sx={avatarStyle}>
                    {firstName &&
                        lastName &&
                        `${firstLetter(firstName) ?? ''} ${firstLetter(lastName) ?? ''}`}
                </Avatar>
            )}
        </Box>
    );
};

export default UserAvatar;



