import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAuth, applyActionCode } from 'firebase/auth';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Button } from '@mui/material';

import BoardingWrapper from '../../ui-components/Common/BoardingWrapper';
import logo from '../../assets/images/logo/satspanda.png';

const MODES = {
    VERIFY_EMAIL: 'verifyEmail',
    RECOVER_EMAIL: 'recoverEmail',
    RESET_PASSWORD: 'resetPassword',
}

const EmailAction = () => {
    const { t } = useTranslation();

    const [urlSearchParams] = useSearchParams();
    const mode = urlSearchParams.get('mode');
    const oobCode = urlSearchParams.get('oobCode');
    const lang = urlSearchParams.get('lang');

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth && oobCode) {
            if (mode === MODES.VERIFY_EMAIL) {
                applyActionCode(auth, oobCode)
                    .then(() => {
                        setIsLoading(false);
                        auth.currentUser?.reload();
                    })
                    .catch((err) => {
                        console.error(JSON.stringify(err));
                        if (err?.code === 'auth/invalid-action-code') {
                            setError(JSON.stringify(err));
                        }
                        setIsLoading(false);
                    });
            }
        }
    }, [auth, oobCode, mode]);

    const handleVerifyCTA = () => {
        const to = auth.currentUser ? '/dashboard' : '/signin';
        navigate(to);
    };

    const handleSignInCTA = () => {
        navigate('/signin');
    };

    return (
        <BoardingWrapper justifyContent="center" bgcolor="#fff">
            <div style={{ maxWidth: '700px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ mt: 4, bgcolor: 'primary.main', height: '68px', width: '68px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} height="63px" width="63px" />
                </Box>
                {mode === MODES.VERIFY_EMAIL && !isLoading && !error && (
                    <>
                        <Typography variant="font1" color="primary.main" sx={{ mt: 4, mb: 4, alignSelf: 'center' }}>
                            {t('thank_you_for_verifying_your_email')}
                        </Typography>
                        <Typography variant="font4Slim" color="neutral.800" sx={{ mb: 8, lineHeight: '1.7' }}>
                            {t('and_welcome_to_satspanda')}
                        </Typography>
                        <Button
                            variant="regular"
                            color="secondary"
                            sx={{
                                mb: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                alignSelf: 'center',
                                cursor: 'pointer',
                                fontSize: '0.9rem',
                            }}
                            onClick={handleVerifyCTA}
                        >
                            {auth.currentUser ? t('Dashboard') : t('sign_in')}
                        </Button>
                    </>
                )}
                {mode == MODES.RESET_PASSWORD && !isLoading && !error && (
                    <>
                        <Typography variant="font1" color="primary.main" sx={{ mt: 4, mb: 4, alignSelf: 'center' }}>
                            {t('your_password_has_been_reset')}
                        </Typography>
                        <Button
                            variant="regular"
                            color="secondary"
                            sx={{
                                mb: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                alignSelf: 'center',
                                cursor: 'pointer',
                                fontSize: '0.9rem',
                            }}
                            onClick={handleSignInCTA}
                        >
                            {t('sign_in')}
                        </Button>
                    </>
                )}
                {!isLoading && error && (
                    <>
                        <Typography variant="font1" color="primary.main" sx={{ mt: 4, mb: 4, alignSelf: 'center' }}>
                            {t('something_went_wrong_please_try_again_later_or_contact_support')}
                        </Typography>
                        <Typography variant="font4Slim" color="neutral.800" sx={{ mb: 8, lineHeight: '1.7' }}>
                            {error}
                        </Typography>
                    </>
                )}
            </div>
        </BoardingWrapper>
    );
};

export default EmailAction;