import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const FlowsChart = ({ flows }) => {
  const chartRef = useRef(null);

  const totalCredit = flows.slice(8).map((item) => -item.total_credit);
  const totalDebit = flows.slice(8).map((item) => item.total_debit);
  const timeData = flows.slice(8).map((item) => item.week);
  const formattedTimeData = timeData.map((date) => {
    const parsedDate = dayjs(date);
    return parsedDate.format('DD MMM');
  });

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
      },
    ],
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: '#000',
        formatter: (value, context) => {
          if (Math.abs(value) >= 1) {
            return Math.abs(value).toString();
          } else {
            return '';
          }
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false,
        grid: {
          display: false,
        },
        beginAtZero: false,

      },
      y: {
        stacked: true,
        display: true,
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    setChartData({
      ...chartData,
      labels: formattedTimeData,
      datasets: [
        {
          label: 'Credit',
          data: totalCredit,
          backgroundColor: 'rgba(255, 205, 210)',
          borderColor: 'rgba(255, 205, 210)',
        },
        {
          label: 'Debit',
          data: totalDebit,
          backgroundColor: 'rgba(165, 214, 167)',
          borderColor: 'rgba(165, 214, 167)',
        },
      ],
    });
  }, [flows]);



  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Bar ref={chartRef} data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default FlowsChart;
