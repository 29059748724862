import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Button, TextField, InputAdornment, Stack, Paper, Box, Typography, Modal, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import panda from '../../../assets/images/logo/satspanda.png';

const SATSPANDA_USERID_REGEXP = /^[a-zA-Z0-9]{28}$/i;
const LN_REGEXP = /^(ln|LN)/;

const {
  REACT_APP_MINIMAL_WITHDRAWAL: MINIMAL_WITHDRAWAL,
} = process.env;

const ACTION_TYPES = {
  PANDASEND: 'pandasend',
  WITHDRAW_TO_INVOICE: 'withdraw-to-ln-invoice',
  WITHDRAW_TO_ADDRESS: 'withdraw-to-ln-address',
};

function ConditionalTooltip({ renderTooltip, children, ...props }) {
  return renderTooltip ? <Tooltip {...props}><span>{children}</span></Tooltip> : children;
}

const SendSatsModal = ({ open, onClose = () => { }, onSend = () => { }, maxSatsAllowed = 0, sendToUuid = null }) => {
  const { t } = useTranslation();

  const [pageIndex, setPageIndex] = useState(0);
  const [actionType, setActionType] = useState(null);
  const [receiverOrInvoice, setReceiverOrInvoice] = useState('');
  const [addressError, setAddressError] = useState('');
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');

  useEffect(() => {
    if (sendToUuid) {
      setPageIndex(1);
      setActionType(ACTION_TYPES.PANDASEND);
      setReceiverOrInvoice(sendToUuid);
    }
  }, [sendToUuid]);

  const handleSendTypeSelection = (type) => () => {
    setPageIndex(1);
    setActionType(type);
  };

  const prehandleClose = (event) => {
    onClose(event);
    setPageIndex(0);
    setReceiverOrInvoice('');
    setAmount('');
    setAmountError('');
    setAddressError('');
  };


  const prehandleSend = (e) => {
    if (actionType === ACTION_TYPES.PANDASEND) {
      const isValidReceiver = SATSPANDA_USERID_REGEXP.test(receiverOrInvoice);

      const parsedAmount = parseFloat(amount);
      const isValidAmount = !isNaN(parsedAmount) && parsedAmount <= maxSatsAllowed;

      if (!isValidReceiver) {
        setAddressError(t('valid_userid_is_required'));
        return;
      }
      if (!isValidAmount) {
        setAmountError(t('enter_valid_amount'));
        return;
      }
    } else if (actionType === ACTION_TYPES.WITHDRAW_TO_INVOICE) {
      const isValidLN = LN_REGEXP.test(receiverOrInvoice);
      if (!isValidLN) {
        setAddressError(t('valid_lightning_address_or_invoice_required'));
        return;
      }
    }
    setAddressError('');
    setAmountError('');

    const payload = {
      actionType
    };
    switch (actionType) {
      case ACTION_TYPES.WITHDRAW_TO_INVOICE:
        payload.invoice = receiverOrInvoice;
        break;
      case ACTION_TYPES.PANDASEND:
        payload.receiver = receiverOrInvoice;
        payload.amount = amount;
        break;
      default:
        console.error('Why did you hack our react app?');
    }
    onSend(payload);
    setReceiverOrInvoice('');
    setAmount('');
    setPageIndex(0);
  };

  const handleMaxClick = () => {
    setAmount(maxSatsAllowed);
  };

  return (
    <Modal open={open} onClose={prehandleClose}>
      <Box>
        <Paper
          sx={{
            width: { xs: '340px', sm: '500px' },
            borderRadius: '12px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              px: 3,
              py: 2,
            }}
          >
            <Avatar
              sx={{
                bgcolor: 'primary.main',
                width: 56,
                height: 56,
                m: '15px auto',
              }}
            >
              <img
                src={panda}
                alt="panda-logo"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Avatar>
            <Typography
              id="modal-modal-title"
              variant="font4"
              sx={{ textAlign: 'center' }}
            >
              {t('send_your_sats_to_a_friend_on_satspadna_or_lightning')}
            </Typography>
            {pageIndex === 0 && (
              <Stack>
                <Button
                  type="submit"
                  fullWidth
                  variant="signin"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleSendTypeSelection(ACTION_TYPES.PANDASEND)}
                >
                  <span style={{ marginRight: '8px' }}>👤</span>{t('send_to_satspanda_userid')}
                </Button>
                {/* <ConditionalTooltip
                    title={`Your current balance ⚡️${maxSatsAllowed} sat is below minimal withdrawal limit ⚡️${MINIMAL_WITHDRAWAL} sat`}
                    renderTooltip={maxSatsAllowed < MINIMAL_WITHDRAWAL}
                  >
                    <Button
                      startIcon={'⚡️'}
                      fullWidth
                      variant='signin-outline'
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleSendTypeSelection(ACTION_TYPES.WITHDRAW_TO_ADDRESS)}
                      disabled={maxSatsAllowed < MINIMAL_WITHDRAWAL}
                    >
                      Withdraw to Ligthning Network <b>Address</b>
                    </Button>
                  </ConditionalTooltip> */}
                <ConditionalTooltip
                  title={`Your current balance ⚡️${maxSatsAllowed} sat is below minimal withdrawal limit ⚡️${MINIMAL_WITHDRAWAL} sat`}
                  renderTooltip={maxSatsAllowed < MINIMAL_WITHDRAWAL}
                >
                  <Button
                    startIcon={'⚡️'}
                    fullWidth
                    variant="signin-outline"
                    sx={{ mt: 1, mb: 2 }}
                    onClick={handleSendTypeSelection(ACTION_TYPES.WITHDRAW_TO_INVOICE)}
                    disabled={maxSatsAllowed < MINIMAL_WITHDRAWAL}
                  >
                    {t('withdraw_to_lightning_invoice')}
                  </Button>
                </ConditionalTooltip>

              </Stack>
            )}
            {pageIndex === 1 && (
              <>
                <Stack
                  direction="column"
                  sx={{ width: { xs: '80%', sm: '61.8%' }, mt: 2, mb: 2 }}
                  gap={1}
                >
                  <TextField
                    label={
                      actionType === ACTION_TYPES.PANDASEND ? 'User ID'
                        : actionType === ACTION_TYPES.WITHDRAW_TO_ADDRESS ? 'lnurl...'
                          : actionType === ACTION_TYPES.WITHDRAW_TO_INVOICE ? 'lnbc...' : ''
                    }
                    variant="outlined"
                    value={receiverOrInvoice}
                    onChange={(e) => setReceiverOrInvoice(e.target.value)}
                    error={!!addressError}
                    helperText={addressError}
                  />
                  {(actionType === ACTION_TYPES.PANDASEND || actionType === ACTION_TYPES.WITHDRAW_TO_ADDRESS) && (
                    <TextField
                      variant="outlined"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      error={!!amountError}
                      helperText={amountError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span>{'⚡️'}</span>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <Button variant="text" onClick={handleMaxClick}>
                            Max
                          </Button>
                        )
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  )}
                  {actionType === ACTION_TYPES.WITHDRAW_TO_INVOICE && (
                    <Typography variant="font5" color="neutral.500">
                      {t('empty_invoice_will_be_debited_with_total_account_balance')}
                    </Typography>
                  )}
                  {(actionType === ACTION_TYPES.WITHDRAW_TO_ADDRESS || actionType === ACTION_TYPES.WITHDRAW_TO_INVOICE) && (
                    <Typography variant="font5" color="neutral.500">
                      {t('we_will_automatically_deduct_routing_fees')}
                    </Typography>
                  )}
                </Stack>
                <Button
                  endIcon={<SendIcon />}
                  variant="small"
                  sx={{ mb: 1, minWidth: 120 }}
                  onClick={prehandleSend}
                >
                  {t('send')}
                </Button>
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default SendSatsModal;