import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfirmProvider } from 'material-ui-confirm';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import store from '../src/redux/store';

import ThemeProvider from './assets/style/ThemeProvider';

import './i18n';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <ConfirmProvider>
            <SnackbarProvider
              autoHideDuration={5000}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <App />
            </SnackbarProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();