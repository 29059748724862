import React from 'react';
import { Typography, Box } from '@mui/material';

const LanguageButton = ({ language, emoji }) => {

  return (
    <Box
      sx={{
        bgcolor: 'white',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: '#ff8c0c',
        },
      }}
    >
      <Typography sx={{ fontSize: '64px' }}>{emoji}</Typography>
      <Typography>{language}</Typography>
    </Box>
  );
};

export default LanguageButton;
