import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { sendEmailVerification } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import { Grid, Stack, Divider, Box, Typography, Button, TextField, Snackbar, Tooltip, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import DashboardContainer from '../../ui-components/Common/Container/DashboardContainer';
import UserAvatar from '../../ui-components/Common/Avatar/UserAvatar';
import { fetchUser, updateUser, uploadProfilePicture } from '../../redux/slices/user';


const Profile = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const user = useSelector((state) => state.user.userFromFirebase);
    const userFromDb = useSelector((state) => state.user.user);
    const profilePic = useSelector((state) => state.user.user?.profilePic);
    const { user: statusUser, profilePicture: statusProfilePic } = useSelector((state) => state.user.status);

    const [previewAvatar, setPreviewAvatar] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isVerifyEnabled, setIsVerifyEnabled] = useState(true);
    const [formFirstName, setFormFirstName] = useState('');
    const [formLastName, setFormLastName] = useState('');

    const email = user?.email;
    const href = profilePic ? `${process.env.REACT_APP_STATIC_URL}/profile-pictures/${profilePic}` : null;

    useEffect(() => {
        if (user && !userFromDb) {
            dispatch(fetchUser(user.uid));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (userFromDb) {
            setFormFirstName(userFromDb.firstName);
            setFormLastName(userFromDb.lastName);
        }
    }, [userFromDb]);

    const handleUpload = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const picture = files[0];
            setPreviewAvatar(picture);
            setPreviewUrl(URL.createObjectURL(picture));
        }
    };

    const handleDrop = (acceptedFiles) => {
        const picture = acceptedFiles[0];
        setPreviewAvatar(picture);
        setPreviewUrl(URL.createObjectURL(picture));
    };

    const handleSave = () => {
        dispatch(updateUser({
            uuid: user.uid,
            firstName: formFirstName,
            lastName: formLastName,
        }));
        if (previewAvatar) {
            dispatch(uploadProfilePicture({ uuid: user.uid, picture: previewAvatar }))
                .then(() => setPreviewAvatar(null))
                .then(() => setPreviewUrl(null));
        }
    }

    const handleVerify = () => {
        sendEmailVerification(user)
            .then(() => {
                setIsVerifyEnabled(false);
                enqueueSnackbar(`${t('verification_email_sent_to')} ${email}`, { variant: 'info' });
                setTimeout(() => setIsVerifyEnabled(true), 120 * 1000);
            })
            .catch((err) => {
                setIsVerifyEnabled(true);
                enqueueSnackbar(t('something_went_wrong_please_try_again_later_or_contact_support'), { variant: 'error' });
            })
    }

    const successAction = (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3, paddingRight: 1, width: '350px' }}>
                <EmailIcon sx={{ mb: 2 }} />
                <Typography>
                    You are almost there! We sent an email to
                </Typography>
                <Typography sx={{ mb: 2, fontWeight: 'bold' }}>
                    {email}
                </Typography>
                <Typography>
                    Just click on the link in that email to complete your signup.
                    If you don't see it, you may need to check your spam folder
                </Typography>
            </Box>
        </>
    );

    const MyDropzone = () => {
        const [isOver, setIsOver] = useState(false);

        return (
            <Dropzone onDrop={handleDrop} onDragEnter={() => setIsOver(true)} onDragLeave={() => setIsOver(false)}
                maxFiles={1}
                accept={{
                    'image/*': ['.png', '.jpg', '.jpeg']
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <Box
                        {...getRootProps()}
                        sx={{
                            height: 130, width: '100%', border: '1px solid #D1D5DB', borderRadius: '8px',
                            bgcolor: isOver ? '#dbdcf6' : 'white',
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'center', alignItems: 'center'
                        }}>
                        <input {...getInputProps()} />
                        <Box sx={{ width: 35, height: 35, borderRadius: '50%', bgcolor: 'neutral.100', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <UploadIcon sx={{ color: 'neutral.400' }} />
                        </Box>
                        <Stack direction="row" alignItems="center">
                            <Button sx={{ '&:hover': { backgroundColor: 'transparent' } }} onClick={handleUpload}>
                                <Typography variant="font5" sx={{ textDecoration: 'underline', fontWeight: 'bold' }} color="neutral.800">
                                    {t('click_to_upload')}
                                </Typography>
                            </Button>
                            <Typography variant="font5" color="neutral.500" sx={{ fontWeight: 'bold' }}>{t('or_drag_and_drop')}</Typography>
                        </Stack>
                        <Typography variant="font5" color="neutral.600">
                            ({t('max_5mb')})
                        </Typography>
                    </Box>
                )}
            </Dropzone>
        );
    };

    return (
        <DashboardContainer justifyContent={'center'} display={'flex'} minHeight={'100vh'}>
            <Box sx={{ width: { md: '800px', lg: '1100px' } }}>
                <Typography variant="font3">{t('my_profile')}</Typography>
                <Grid container sx={{ mt: 3 }} spacing={3}>
                    <Grid item md={12} lg={12}>
                        <Stack direction="row" alignItems="center" gap={2}>
                            <UserAvatar
                                firstName={userFromDb?.firstName}
                                lastName={userFromDb?.lastName}
                                href={href}
                                bgColor={'neutral.300'}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="font5Mid" color="neutral.800" sx={{ fontWeight: 'bold' }}>{userFromDb?.firstName} {userFromDb?.lastName}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography variant="font5Mid" color="neutral.500">{email}</Typography>
                                    <Box sx={{ ml: 2 }}>
                                        {!user?.emailVerified && (
                                            <Button variant="text"
                                                onClick={handleVerify}
                                                disabled={!isVerifyEnabled}
                                            >
                                                {t('verify')}
                                            </Button>)}
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Tooltip title={t('your_user_id')} TransitionProps={{ timeout: 1500 }}>
                                        <Typography variant="font5Mid" color="neutral.500">{userFromDb?.uuid}{'  '}</Typography>
                                    </Tooltip>
                                    <Tooltip title={t('copy_to_clipboard')} TransitionProps={{ timeout: 1500 }}>
                                        <IconButton size="small" onClick={() => navigator.clipboard.writeText(userFromDb?.uuid)}>
                                            <ContentCopyIcon sx={{ fontSize: 13 }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} md={5} lg={4}>
                        <Stack direction="column" sx={{ alignItems: { xs: 'center', md: 'flex-start' } }}>
                            <Typography variant="font5Mid" color="neutral.800" sx={{ fontWeight: 'bold' }}>{t('public_profile')}</Typography>
                            <Typography variant="font5" color="neutral.500">{t('this_will_be_displayed_on_your_profile')}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7} lg={5}>
                        <Stack direction="column" sx={{ gap: '12px' }}>
                            <TextField sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'neutral.300' }, borderRadius: '8px', } }}
                                label={t('first_name')} value={formFirstName} onChange={(e) => setFormFirstName(e.target.value)} />
                            <TextField sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'neutral.300' }, borderRadius: '8px', } }}
                                label={t('last_name')} value={formLastName} onChange={(e) => setFormLastName(e.target.value)}>
                            </TextField>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} md={5} lg={4}>
                        <Stack direction="column" sx={{ alignItems: { xs: 'center', md: 'flex-start' } }}>
                            <Typography variant="font5Mid" color="neutral.800" sx={{ fontWeight: 'bold' }}>{t('your_avatar')}</Typography>
                            < Typography variant="font5" color="neutral.500" >{t('update_your_avatar')}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={2} lg={1} sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                        <UserAvatar
                            href={previewUrl}
                            width={'60px'} height={'60px'} bgColor={'neutral.300'} />
                    </Grid>
                    <Grid item xs={12} md={5} lg={4} sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                        <MyDropzone></MyDropzone>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: '8px' }}>
                        <LoadingButton variant="regular" onClick={handleSave} loading={statusUser === 'loading' || statusProfilePic === 'loading'}>{t('save_changes')}</LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </DashboardContainer>
    );
};

export default Profile;