import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Stepper, Step, StepLabel, Box, Typography, Skeleton, Tooltip, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { ColorlibConnector, ColorlibStepIconRoot, ArrowBtnBox } from './LanguagePanel';
import SpreadButton from '../../Common/Buttons/SpreadButton';

const LanguageCoursePanel = ({
  journeys = [],
  languages,
  languageStatus,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = journeys.length;
  const isSubscribedToJourneys = journeys.length >= 1;

  useEffect(() => {
    if (journeys && journeys.length > 0) {
      handleActiveStep();
    }
  }, [journeys]);

  const handleFind = () => {
    navigate('/onboarding');
  };

  const handleNext = () => {
    setIndex((prevIndex) => prevIndex + 1);
  };
  const handleBack = () => {
    setIndex((prevIndex) => prevIndex - 1);
  };

  const handleActiveStep = () => {
    const completedModules = journeys[index]?.JourneyModules?.filter((jm) => jm.isCompleted);
    const nextActiveStep = completedModules.length > 0 ? completedModules.length : 0;
    setActiveStep(nextActiveStep);
  }

  const handleNavigateToModule = (module, stepIndex) => {
    if (stepIndex === activeStep) {
      let targetedModule = module.Module.name;
      const journeyWithModule = journeys.find((journey) =>
        journey.JourneyModules.some((jmodule) => jmodule.Module.name === targetedModule)
      );

      if (journeyWithModule) {
        navigate(`/learning/${journeyWithModule.id}`);
      } else {
        return;
      };
    };

  };

  if (languageStatus === 'loading') {
    return (
      <Grid
        container
        alignItems="center"
        sx={{ height: '80%', position: 'relative' }}
      >
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Skeleton variant="rect" width={'50%'} height={40} />
        </Grid>
        <Box
          sx={{
            position: 'absolute',
            bottom: -20,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Skeleton />
        </Box>
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{ height: '80%', position: 'relative' }}
      >
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Tooltip
            sx={{ cursor: 'pointer' }}
            title={
              languages.find((l) => l.locale === journeys[activeStep]?.language)
                ?.name
            }
          >
            <Box sx={{ mb: 1 }}>
              <Typography style={{ fontSize: '32px' }}>
                {
                  languages.find(
                    (l) => l.locale === journeys[index]?.language
                  )?.emoji
                }
              </Typography>
            </Box>
          </Tooltip>
          <Stepper connector={<ColorlibConnector active={activeStep} />} activeStep={activeStep} alternativeLabel sx={{ width: '80%' }}>
            {journeys[index]?.JourneyModules.map((jm, i) => (
              <Step key={i}>
                <Button sx={{ '&:hover': { backgroundColor: 'transparent' }, }} onClick={() => handleNavigateToModule(jm, i)}>
                  <StepLabel StepIconComponent={() =>
                    <ColorlibStepIconRoot className={i < activeStep ? 'completed' : i === activeStep ? 'active' : ''} i={i}>{i + 1}</ColorlibStepIconRoot>}>
                    {`${jm.Module.name}`}
                  </StepLabel>
                </Button>
              </Step>
            ))}
          </Stepper>
          {!isSubscribedToJourneys && (
            <Box sx={{ position: 'absolute', top: 70, left: '50%', transform: 'translateX(-50%)', }}>
              <Typography variant="font5mid" color="neutral.500">Discover modules filled with knowledge, conquer lessons, and earn rewards in sats along the way. Begin your journey now!</Typography>
            </Box>
          )}
          {isSubscribedToJourneys && (
            <>
              <ArrowBtnBox left="5%">
                <IconButton
                  size="small"
                  onClick={handleBack}
                  disabled={index === 0}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              </ArrowBtnBox>
              <ArrowBtnBox right="5%">
                <IconButton
                  size="small"
                  onClick={handleNext}
                  disabled={index === maxSteps - 1}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </ArrowBtnBox>
            </>
          )}

        </Grid>
        <SpreadButton
          text={t('find_journey')}
          icon={<SearchIcon sx={{ fontSize: '24px' }} />}
          onClick={handleFind}
        />
      </Grid>
    </>
  );
};

export default LanguageCoursePanel;
