import React, { useState } from 'react';

import { Box, Typography, IconButton } from '@mui/material';

const JourneyButton = ({ onClick = () => { }, icon, text }) => {

    return (
        <Box>
            <IconButton onClick={onClick}
                sx={{
                    width: '140px',
                    p: '10px 4px',
                    bgcolor: 'primary.main',
                    color: 'white',
                    borderRadius: '12px',
                    '&:hover': {
                        bgcolor: 'primary.dark',
                        color: 'white',
                    },
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {icon}
                    <Typography variant="font5mid" sx={{ ml: '4px', fontWeight: 'bold' }}>
                        {text}
                    </Typography>
                </Box>
            </IconButton>
        </Box>
    )
};

export default JourneyButton;