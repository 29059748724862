import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const fetchBtcMarketData = createAsyncThunk('market-data/fetchBtc', async () => {
    const response = await client('/v1/market-data/btc', { method: 'get' });
    return response.data;
});

const INITIAL_STATE = {
    status: 'idle',
    btc: {},
};

const marketDataSlice = createSlice({
    name: 'marketData',
    initialState: INITIAL_STATE,
    extraReducers: (builder) =>
        builder
            .addCase(fetchBtcMarketData.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchBtcMarketData.fulfilled, (state, action) => {
                state.status = 'idle';
                state.btc = action.payload;
            })
            .addCase(fetchBtcMarketData.rejected, (state, action) => {
                state.status = 'idle';
            })
});

export default marketDataSlice.reducer;