import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import { Box, Button, Typography, LinearProgress, Menu, MenuItem, Card, CardActions, CardContent, CardHeader, Divider, Tooltip, IconButton, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserStats, unsubscribeUserFromJourney } from '../../../redux/slices/user';

const JourneyCard = ({ journey, languages = [] }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const confirm = useConfirm();

    const uuid = useSelector((state) => state.user.userFromFirebase.uid);

    const [progress, setProgress] = useState(Math.random() * 100);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const language = languages.find((l) => l.locale === journey.language);

    const handleUnsubscribe = () => {
        confirm({ title: 'Unsubscribe from journey', description: 'Your progress will be lost, are you sure?' })
            .then(() => dispatch(unsubscribeUserFromJourney({ uuid, journeyId: journey.id })))
            .then(() => dispatch(fetchUserStats({ uuid })))
            .catch(() => { });
    };

    const handleContinue = () => {
        navigate(`/learning/${journey.id}`)
    };

    return (
        <Card sx={{ width: 360, height: 210, borderRadius: '12px' }}>
            <CardHeader
                avatar={
                    <Typography sx={{ fontSize: '2rem' }}>
                        {language?.emoji}
                    </Typography>
                }
                title={
                    <Tooltip title={journey?.PrebuiltJourney?.name}>
                        <Typography variant="font4" noWrap={true}>
                            {journey?.PrebuiltJourney?.name}
                        </Typography>
                    </Tooltip>
                }
                sx={{
                    '& .MuiCardHeader-content': {
                        display: 'block',
                        overflow: 'hidden',
                    },
                }}
                subheader={language?.name}
                action={
                    <IconButton
                        aria-label="settings"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
            />
            <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '40%', px: 3 }}>
                {/* <Box>
                    {journey?.JourneyModules.map((jm, i) => (
                        <Typography variant="body2" color="text.secondary" key={i}>
                            {jm.Module.name}
                        </Typography>
                    ))}
                </Box> */}
                <Box sx={{ mb: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <LinearProgress variant="determinate" sx={{
                        height: '8px',
                        borderRadius: '6px',
                    }}
                        value={progress} />
                </Box>
            </CardContent>
            <Divider sx={{}} />
            <CardActions sx={{ p: 0, px: 3, justifyContent: 'space-between', alignItems: 'center' }}>
                <Tooltip title={`You will be awarded a completion bonus of ${journey?.satsbonus} sats when all modules within the journey are finished.`}>
                    <Typography variant="font5mid" sx={{ fontWeight: 'bold' }}>
                        ⚡️{journey?.satsbonus}
                    </Typography>
                </Tooltip>
                <Button size="small" sx={{ mt: 1, mb: 1 }} onClick={handleContinue}>Continue</Button>
            </CardActions>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => setAnchorEl(null)}
            >
                <MenuItem onClick={handleUnsubscribe}>
                    <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    Unsubsribe
                </MenuItem>
            </Menu>
        </Card >
    );
};

export default JourneyCard;