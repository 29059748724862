import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { BaseCardWrapper, BaseCardBox } from './CardBaseStyles';

// TurnCard
export const TurnCardWrapper = styled(BaseCardWrapper)(({ theme }) => ({
    justifyContent: 'space-between',
    width: 310,
    height: 600,
}));

export const TurnCardBox = styled(BaseCardBox)(({ theme }) => ({
    padding: 32,
    width: 250,
    minHeight: 350,
}));

export const TurnCardFrontCard = styled('div')({
    width: 90,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backfaceVisibility: 'hidden',
    lineHeight: 1.3,
});

export const TurnCardP = styled(Typography)({
    fontSize: '40px',
    textAlign: 'center',
});


// PhraseOptionsCard
export const PhraseOptionsWrapper = styled(BaseCardWrapper)(({ theme }) => ({
    width: 310,
    minHeight: 630
}));

export const PhraseOptionsBox = styled(BaseCardBox)(({ theme }) => ({
    width: 250,
    minHeight: 260,
}));

export const PhraseOptionsFrontCard = styled('div')({
    height: '100%',
    width: '100%',
    padding: '16px 20px',
    lineHeight: 1.2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
});

export const PhraseOptionsPhrase = styled(Typography)({
    fontSize: '2rem',
    lineHeight: 1.2,
});

export const PhraseOptionsDescription = styled(Typography)(({ theme }) => ({
    marginTop: '18px',
    fontSize: '1rem',
    lineHeight: 1.2,
    color: theme.palette.background.cardDescription, 
}));


// WordOptionsCard
export const WordOptionsWrapper = styled(BaseCardWrapper)(({ theme }) => ({
    width: 310,
    minHeight: 630
}));

export const WordOptionsBox = styled(BaseCardBox)(({ theme }) => ({
    width: 250,
    minHeight: 280,
}));

export const WordOptionsFrontCard = styled('div')({
    height: '100%',
    width: '100%',
    padding: '16px 20px',
    lineHeight: 1.2,
});

export const WordOptionsWord = styled(Typography)({
    fontSize: '2.5rem',
    lineHeight: 1.2,
});

export const WordOptionsDescription = styled(Typography)(({ theme }) => ({
    marginTop: '14px',
    fontSize: '1rem',
    lineHeight: 1.2,
    color: theme.palette.background.cardDescription, 
}));


// AcronymTestCard
export const AcronymTestWrapper = styled(BaseCardWrapper)(({ theme }) => ({
    width: 310,
    height: 570,
}));

export const AcronymTestBox = styled(BaseCardBox)(({ theme }) => ({
    padding: 5,
    width: 250,
    minHeight: 350,
}));

export const AcronymTestFrontCard = styled('div')({
    width: '100%',
    padding: 15,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backfaceVisibility: 'hidden',
    lineHeight: 1.3,
});

export const AcronymTestWord = styled(Typography)({
    fontSize: '2.5rem',
    lineHeight: 1.2,
});

export const AcronymTestSentence = styled(Typography)(({ theme }) => ({
    fontSize: '1.3rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: theme.palette.common.black, 
}));


// GrammarCard
export const GrammarWrapper = styled(BaseCardWrapper)(({ theme }) => ({
    justifyContent: 'space-between',
    width: 310,
    minHeight: 600
}));

export const GrammarBox = styled(BaseCardBox)(({ theme }) => ({
    width: 250,
    minHeight: 430,
}));

export const GrammarFrontCard = styled('div')({
    height: '100%',
    width: '100%',
    padding: '16px 20px',
    lineHeight: 1.2,
});

export const GrammarCardTitle = styled(Typography)({
    fontSize: '1rem',
    fontWeight:'bold',
    lineHeight: 1.2,
    marginBottom: '6px'
});

export const GrammarCardP = styled(Typography)({
    fontSize: '1rem',
    lineHeight: 1.2,
    marginBottom: '6px'
});


export const GrammarCardExample = styled(Typography)({
    fontSize: '1rem',
    fontWeight:'bold',
    lineHeight: 1.2,
    marginBottom: '6px'
});

export const GrammarPinyin = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: 1.2,
    marginBottom: '6px',
    color: theme.palette.primary.main, 
}));
