import b1 from '../../assets/images/games/mahjong/b1.svg';
import b2 from '../../assets/images/games/mahjong/b2.svg';
import b3 from '../../assets/images/games/mahjong/b3.svg';
import b4 from '../../assets/images/games/mahjong/b4.svg';
import b5 from '../../assets/images/games/mahjong/b5.svg';
import b6 from '../../assets/images/games/mahjong/b6.svg';
import b7 from '../../assets/images/games/mahjong/b7.svg';
import b8 from '../../assets/images/games/mahjong/b8.svg';
import b9 from '../../assets/images/games/mahjong/b9.svg';

import c1 from '../../assets/images/games/mahjong/c1.svg';
import c2 from '../../assets/images/games/mahjong/c2.svg';
import c3 from '../../assets/images/games/mahjong/c3.svg';
import c4 from '../../assets/images/games/mahjong/c4.svg';
import c5 from '../../assets/images/games/mahjong/c5.svg';
import c6 from '../../assets/images/games/mahjong/c6.svg';
import c7 from '../../assets/images/games/mahjong/c7.svg';
import c8 from '../../assets/images/games/mahjong/c8.svg';
import c9 from '../../assets/images/games/mahjong/c9.svg';

import d1 from '../../assets/images/games/mahjong/d1.svg';
import d2 from '../../assets/images/games/mahjong/d2.svg';
import d3 from '../../assets/images/games/mahjong/d3.svg';
import d4 from '../../assets/images/games/mahjong/d4.svg';
import d5 from '../../assets/images/games/mahjong/d5.svg';
import d6 from '../../assets/images/games/mahjong/d6.svg';
import d7 from '../../assets/images/games/mahjong/d7.svg';
import d8 from '../../assets/images/games/mahjong/d8.svg';
import d9 from '../../assets/images/games/mahjong/d9.svg';

import h from '../../assets/images/games/mahjong/h.svg';

export default {
    b1,
    b2,
    b3,
    b4,
    b5,
    b6,
    b7,
    b8,
    b9,

    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    c7,
    c8,
    c9,

    d1,
    d2,
    d3,
    d4,
    d5,
    d6,
    d7,
    d8,
    d9,

    h,
};