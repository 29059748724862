import { React, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { shuffleArray } from '../shuffle';

import { TurnCardWrapper, TurnCardBox, TurnCardFrontCard, TurnCardP } from './CardStyles';
import CardButton from '../Buttons/CardButton';
import ProgressBar from '../ProgressBar/ProgressBar.jsx';

const TurnCard = ({ lessonData, progress, onNextCard }) => {
  const options = lessonData?.structure?.options;

  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [answerRevealed, setAnswerRevealed] = useState(false);

  useEffect(() => {
    setShuffledOptions(shuffleArray(options));
    setAnswerRevealed(false);
  }, [options]);

  return (
    <>
      <TurnCardWrapper>
        {lessonData ? (
          <TurnCardBox>
            <TurnCardFrontCard>
              <TurnCardP>{lessonData.structure.hanzi}</TurnCardP>
            </TurnCardFrontCard>
          </TurnCardBox>
        ) : (
          <TurnCardBox>
            <TurnCardFrontCard>
              <TurnCardP>Loading...</TurnCardP>
            </TurnCardFrontCard>
          </TurnCardBox>
        )}
        <ProgressBar progress={progress} />
        <Grid container spacing={1}>
          {shuffledOptions.map((item, index) => (
            <Grid item xs={6} key={index}>
              <CardButton
                onClick={() => {
                  onNextCard(item);
                  setAnswerRevealed(true);
                }}
                text={item}
                color={answerRevealed && item === options[0] ? '#5CEF9F' : '#44d0d8'}
                bcolor={answerRevealed && item === options[0] ? '#5CEF9F' : '#44d0d8'}
              />
            </Grid>
          ))}
        </Grid>
      </TurnCardWrapper>
    </>
  );
};

export default TurnCard;
