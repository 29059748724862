import { React, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Box';

const StyledButton = styled(Button)(({ theme, color, hcolor, bcolor }) => ({
  backgroundColor: color || theme.palette.secondary.main,
  color: theme.palette.common.white,
  boxShadow: 'none',
  padding: '10px 0',
  borderRadius: '10px',
  border: `1px solid ${bcolor}`,
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 500,
  cursor: 'pointer',
  width: '100%',
  '&:hover': {
    backgroundColor: hcolor || theme.palette.secondary.dark,
    border: `1px solid ${bcolor}`,
  },
}));

const CardButton = ({ onClick, text, color, hcolor }) => {
  return (
    <StyledButton onClick={onClick} color={color} hcolor={hcolor}>
      {text}
    </StyledButton>
  );
};

export default CardButton;
