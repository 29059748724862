import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

import { Paper, Typography, Stack, Divider } from '@mui/material';

const LatestActionItem = ({ type, createdAt, meta, ...rest }) => {
    const { t } = useTranslation();

    const languages = useSelector((state) => state.languages.languages);

    const SETTINGS = {
        JOINED_SATSPANDA: {
            emoji: '🦄',
            formatter: () => t('welcome_to_satspanda'),
        },
        LANGUAGE_ADDED: {
            emoji: '📣',
            formatter: ({ language, scale }) => `${t('added')} ${languages.find((l) => l.locale === language)?.emoji}${languages.find((l) => l.locale === language)?.nativeName} ${t('to_portfolio')}`,
        },
        JOURNEY_SIGNUP: {
            emoji: '🚗',
            formatter: () => t('signed_up_for_a_new_journey'),
        },
        MODULE_SIGNUP: {
            emoji: '💪',
            formatter: ({ name }) => (<>{t('signed_up_for_a_new_module')}: <i>{name}</i> </>),
        },
        GAME_PLAYED: {
            emoji: '🎯',
            formatter: ({ game, payout }) => `${payout >= 0 ? t('won') : t('lost')} ⚡️${Math.abs(payout)} ${t('sat_playing')} ${t(game)}`,
        },
        LESSON_SUBMITTED: {
            emoji: '🏆',
            formatter: ({ lesson, grade }) => (<>{t('finished')} <i>{lesson}</i>. {t('grade')}: {numeral(grade * 100).format('%')}</>),
        }
    };

    const typeSettings = SETTINGS[type];
    const formattedDate = dayjs(createdAt).format('MMMM DD, YYYY HH:mm:ss');

    return (
        <Stack sx={{ p: 0, mt: 2 }}>
            <Stack direction="row" justifyContent="space-between" gap={1}>
                <Typography>{typeSettings.emoji}</Typography>
                <Typography variant="font5mid" color="neutral.900">{typeSettings.formatter(meta)}</Typography>
                <Typography variant="font5mid" color="neutral.400">{formattedDate}</Typography>
            </Stack>
            <Divider />
        </Stack>
    )
};

const LatestActionsPanel = ({ activities = [] }) => {
    return (
        <Paper
            sx={{ overflow: 'auto', boxShadow: 'none', height: '85%' }}
        >
            {activities?.map((a, index) => (
                <LatestActionItem type={a.type} key={`la-${index}`} {...a} />
            ))}
        </Paper>
    );
};

export default LatestActionsPanel;