import { React, useState, useEffect } from 'react';
import HighlightWords from 'react-highlight-words';
import { Grid } from '@mui/material';

import { shuffleArray } from '../shuffle';
import { WordOptionsWrapper, WordOptionsBox, WordOptionsFrontCard, WordOptionsWord, WordOptionsDescription } from './CardStyles';
import CardButton from '../Buttons/CardButton';
import ProgressBar from '../ProgressBar/ProgressBar.jsx';


const WordOptionsCard = ({ lessonData: { structure: { word, sampleSentence, synonyms, antonyms, unrelated } }, onNextCard, progress }) => {
    const buttonOptions = [...synonyms, ...antonyms, ...unrelated];

    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [answerRevealed, setAnswerRevealed] = useState(false);

    useEffect(() => {
        setShuffledOptions(shuffleArray(buttonOptions));
        setAnswerRevealed(false);
    }, [synonyms, antonyms, unrelated]);

    return (
        <>
            <WordOptionsWrapper>
                <WordOptionsBox>
                    <WordOptionsFrontCard>
                        <WordOptionsWord>{word}</WordOptionsWord>
                        <WordOptionsDescription>
                            <HighlightWords
                                searchWords={[word]}
                                textToHighlight={sampleSentence}
                                highlightStyle={{ color: 'black', backgroundColor: 'white' }}
                            />
                        </WordOptionsDescription>
                    </WordOptionsFrontCard>
                </WordOptionsBox>
                <ProgressBar progress={progress} mt={3} />
                <Grid container spacing={1}>
                    {shuffledOptions.map((item) => (
                        <Grid item xs={6} key={item}>
                            <CardButton
                                onClick={() => {
                                    onNextCard(item);
                                    setAnswerRevealed(true);
                                }}
                                text={item}
                                color={answerRevealed && synonyms.includes(item) ? '#5CEF9F' : '#44d0d8'}
                                bcolor={answerRevealed && synonyms.includes(item) ? '#5CEF9F' : '#44d0d8'}
                            ></CardButton>
                        </Grid>
                    ))}
                </Grid>
            </WordOptionsWrapper >
        </>
    );
};

export default WordOptionsCard;



