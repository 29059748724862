import React from 'react';
import { Tooltip } from '@mui/material';

const ConditionalTooltip = ({ renderTooltip, children, ...props }) => {
  return renderTooltip ? (
    <Tooltip placement="top" followCursor={true} {...props}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default ConditionalTooltip;