import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Grid, Typography, Divider } from '@mui/material';

import { gameIdToName } from '../../Games/games';

const BalanceTransactionsItem = ({
  entryType,
  type,
  amount,
  meta = {},
  createdAt,
}) => {
  const { t } = useTranslation();

  const {
    lesson,
    module,
    from,
    to,
    game,
    bonus
  } = meta;
  const inputDate = createdAt;
  const formattedDate = dayjs(inputDate).format('MMMM DD, YYYY HH:mm:ss');

  const entryTypeCap = entryType.toUpperCase();

  return (
    <Grid
      item
      xs={12}
      sx={{
        px: 0,
      }}
    >
      <Stack sx={{ p: 0, mt: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1}>
            <Box
              sx={{
                bgcolor:
                  entryTypeCap === 'CREDIT'
                    ? 'pastelSecondary.pink'
                    : 'pastelSecondary.green',
                py: '2px',
                px: '10px',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ color: 'neutral.700', fontWeight: 'bold' }}>
                {entryTypeCap}
              </Typography>
            </Box>
            <Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              {amount} sat
            </Typography>
          </Stack>
          <Typography color="neutral.400">{formattedDate}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          {type === 'LESSON_PAYOUT' && (
            <>
              <Typography color="neutral.900" sx={{ marginLeft: '0.3rem' }}>
                {lesson}
              </Typography>
              <Typography color="neutral.900">{module}</Typography>
            </>
          )}
          {type === 'INTERNAL_TRANSFER' && (
            <Typography>Internal transfer to {`${from || to || 'unknown'}`}</Typography>
          )}
          {type === 'GAME_PAYOUT' && (
            <Typography color="neutral.900" sx={{ marginLeft: '0.3rem' }}>
              {t('Played')} {t(game)}
            </Typography>
          )}
          {type === 'SIGNUP_BONUS' && (
            <Typography color="neutral.900" sx={{ marginLeft: '0.3rem' }}>
              Received signup bonus ⚡️{amount}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Divider />
    </Grid>
  );
};

export default BalanceTransactionsItem;
