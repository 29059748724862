import { React, useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { shuffleArray } from '../shuffle';

import { AcronymTestWrapper, AcronymTestBox, AcronymTestFrontCard, AcronymTestWord, AcronymTestSentence } from './CardStyles';

import CardButton from '../Buttons/CardButton';
import ProgressBar from '../ProgressBar/ProgressBar.jsx';


const AcronymTest = ({ lessonData, status, onNextCard }) => {
    const options = lessonData?.structure?.options;
    const sentence = lessonData?.structure?.sentence;
    const missingWord = lessonData?.structure?.missingWord;

    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [answerRevealed, setAnswerRevealed] = useState(false);

    useEffect(() => {
        setShuffledOptions(shuffleArray(options));
        setAnswerRevealed(false);
    }, [options]);

    const createGap = (sentence, missingWord) => {
        const splittedSentence = sentence.split('');

        return splittedSentence.map((word, index) => (
            word === missingWord ? (
                <Typography variant="inherit" key={index} component="mark" style={{ backgroundColor: 'white' }}>
                    ____
                </Typography>
            ) : (
                <Typography variant="inherit" key={index} component="span">
                    {word}
                </Typography>
            )
        ));
    };

    return (
        <>
            <AcronymTestWrapper>
                <AcronymTestBox>
                    <AcronymTestFrontCard>
                        {status === 'loading' ? (
                            <AcronymTestSentence>Loading...</AcronymTestSentence>
                        ) : (
                            <AcronymTestSentence>{createGap(sentence, missingWord)}</AcronymTestSentence>
                        )}

                    </AcronymTestFrontCard>
                </AcronymTestBox>

                <ProgressBar progress={80} mt={3} />
                <Grid container spacing={1}>
                    {shuffledOptions.map((item, index) => (
                        <Grid item xs={6} key={index}>
                            <CardButton
                                onClick={() => {
                                    onNextCard(item);
                                    setAnswerRevealed(true);
                                }}
                                text={item}
                                color={answerRevealed && item === options[0] ? '#5CEF9F' : '#44d0d8'}
                                bcolor={answerRevealed && item === options[0] ? '#5CEF9F' : '#44d0d8'}
                            />
                        </Grid>
                    ))}
                </Grid>

            </AcronymTestWrapper>
        </>
    );
};

export default AcronymTest;
