import React from 'react';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

import { Grid, Stack, Typography, Box, Skeleton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

numeral.options.scalePercentBy100 = false;

const PercentageChange = ({ label, value, isUp }) => {
  return (
    <Stack direction="row">
      <Typography variant="option" color="neutral.600">
        {label} :
      </Typography>
      <Typography
        variant="option"
        color={isUp ? 'green' : 'red'}
        sx={{ position: 'relative', marginLeft: 2 }}
      >
        {isUp ? (
          <ArrowDropUpIcon
            sx={{ color: 'green', position: 'absolute', left: '-20px' }}
          />
        ) : (
          <ArrowDropDownIcon
            sx={{ color: 'red', position: 'absolute', left: '-20px' }}
          />
        )}
        <span>{value}</span>
      </Typography>
    </Stack>
  );
};

const MarketData = ({
  btcusd,
  volume_24h,
  percent_change_24h,
  percent_change_7d,
  percent_change_90d,
  status
}) => {
  const { t } = useTranslation();

  const formattedBTC = numeral(btcusd).format('0,0.00');
  const formattedVolume24 = numeral(volume_24h).format('0.0a');
  const formattedPercent24 = numeral(percent_change_24h).format('0.00%',);
  const formattedPercent7d = numeral(percent_change_7d).format('0.00%');
  const formattedPercent90d = numeral(percent_change_90d).format('0.00%');

  const up_24h = percent_change_24h > 0;
  const up_7d = percent_change_7d > 0;
  const up_90d = percent_change_90d > 0;

  if (status === 'loading') {
    return (
      <Grid item xs={12} sx={{ height: '100%' }}>
        <Stack
          sx={{
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
          <Skeleton variant="text" width={150} height={30} />
        </Stack>
        <Box>
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sx={{ height: '100%' }}>
      <Stack
        sx={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <Typography variant="option" color="neutral.600">
          BTCUSD : <span>{formattedBTC}</span>
        </Typography>
        <Typography variant="option" color="neutral.600">
          Vol :{' '}<span>{formattedVolume24}</span>
        </Typography>
        <PercentageChange label="1H " value={formattedPercent24} isUp={up_24h} />
        <PercentageChange label="7d" value={formattedPercent7d} isUp={up_7d} />
        <PercentageChange
          label="90d"
          value={formattedPercent90d}
          isUp={up_90d}
        />
      </Stack>
      <Box>
        <Typography variant="comment" color="neutral.400">
          *{t('data_might_be_delayed_by_up_to_10_minutes')}
        </Typography>
      </Box>
    </Grid>
  );
};

export default MarketData;
