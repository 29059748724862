import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

export const fetchUser = createAsyncThunk('users/fetchUser', async (uuid) => {
  const response = await client(`/v1/users/${uuid}`, { method: 'get' });
  return response.data;
});

export const updateUser = createAsyncThunk('users/update-user', async ({ uuid, firstName, lastName, passedInitialOnboarding }) => {
  const response = await client(`/v1/users/${uuid}`,
    {
      method: 'put',
      body: {
        ...firstName && { firstName },
        ...lastName && { lastName },
        ...passedInitialOnboarding && { passedInitialOnboarding },
      }
    });
  return response.data;
});

export const fetchUserStats = createAsyncThunk('users/stats', async ({ uuid, ignoreCache }) => {
  const response = await client(`/v1/users/${uuid}/stats`, {
    method: 'get',
    ...ignoreCache && { headers: { 'Cache-Control': 'no-cache' } }
  });
  return response.data;
});

export const fetchUserTransactions = createAsyncThunk('users/transactions', async ({ uuid, createdAtFrom }) => {
  const response = await client(`/v1/users/${uuid}/transactions${createdAtFrom ? `?createdAtFrom=${createdAtFrom}` : ''}`, { method: 'get' });
  return response.data;
});

export const fetchUserJourneys = createAsyncThunk('users/journeys', async (uuid) => {
  const response = await client(`/v1/users/${uuid}/journeys`, { method: 'get' });
  return response.data;
});

export const signUpUserToJourney = createAsyncThunk('users/signUpJourney', async ({ uuid, journeyId }) => {
  const response = await client(`/v1/users/${uuid}/journeys`, {
    method: 'put',
    body: { journeyId }
  });
  return response.data;
});

export const unsubscribeUserFromJourney = createAsyncThunk('users/unsubscribeJourney', async ({ uuid, journeyId }) => {
  const response = await client(`/v1/users/${uuid}/journeys/${journeyId}`, {
    method: 'delete',
  });
  return response.data;
});

export const fetchUserActivities = createAsyncThunk('users/activities', async (uuid) => {
  const response = await client(`/v1/users/${uuid}/activities`, { method: 'get' });
  return response.data;
});

export const sendSats = createAsyncThunk('users/sendSats', async ({ sender, receiver, invoice, amount, action }) => {
  const response = await client(`/v1/users/${sender}/satsbalance?action=${action}`, {
    method: 'post',
    body: { receiver, amount, invoice }
  });
  return response.data;
});

export const fetchUserEarnings = createAsyncThunk('users/earnings', async (uuid) => {
  const response = await client(`/v1/users/${uuid}/stats/earnings`, { method: 'get' });
  return response.data;
});

export const fetchUserFlows = createAsyncThunk('users/flows', async (uuid) => {
  const response = await client(`/v1/users/${uuid}/stats/flows`, { method: 'get' });
  return response.data;
});

export const fetchRunningBalance = createAsyncThunk('users/running-balance', async (uuid) => {
  const response = await client(`/v1/users/${uuid}/stats/running-balance`, { method: 'get' });
  return response.data;
});

export const fetchInvoiceForUser = createAsyncThunk('users/invoice', async ({ uuid, amount }) => {
  const response = await client(`/v1/invoices?user=${uuid}&amount=${amount}`, { method: 'get' });
  return response.data;
});

export const fetchInvoiceStatus = createAsyncThunk('users/invoice-paid', async (invoiceId) => {
  const response = await client(`/v1/invoices/${invoiceId}`, { method: 'get' });
  return response.data;
});

export const uploadProfilePicture = createAsyncThunk('users/profile-picture', async ({ uuid, picture }) => {
  const fData = new FormData();
  fData.append('profile-picture', picture);

  const response = await client(`/v1/users/${uuid}/profile-picture`, {
    method: 'post',
    data: fData,
  });

  return response.data;
});

const INITIAL_STATE = {
  status: {
    user: 'idle',
    userStats: 'idle',
    userTransactions: 'idle',
    satsbalance: 'idle',
    journeys: 'idle',
    earnings: 'idle',
    flows: 'idle',
    runningBalance: 'idle',
    activities: 'idle',
    invoice: 'idle',
    profilePicture: 'idle',
  },
  userFromFirebase: null,
  user: null,
  satsbalance: 0,
  completedLessons: null,
  completedModules: null,
  journeys: [],
  earnings: [],
  flows: [],
  lineItems: [],
  activities: [],
  runningBalance: [],
  invoice: null,
  invoicePaid: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    cleanLoggedUser: (state, action) => {
      return {
        ...INITIAL_STATE,
      }
    },
    cleanInvoice: (state, action) => {
      return {
        ...state,
        invoice: null,
        invoicePaid: null,
      }
    },
    setUserFromFirebase: (state, action) => {
      return {
        ...state,
        userFromFirebase: action.payload,
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.status.user = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status.user = 'idle';
        state.user = action.payload;
        state.satsbalance = action.payload.satsbalance;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status.user = 'idle';
      })
      .addCase(updateUser.pending, (state, action) => {
        state.status.user = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status.user = 'idle';
        state.user = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status.user = 'idle';
      })
      .addCase(fetchUserTransactions.pending, (state, action) => {
        state.status.userTransactions = 'loading';
      })
      .addCase(fetchUserTransactions.fulfilled, (state, action) => {
        state.status.userTransactions = 'idle';
        state.satsbalance = action.payload.satsbalance;
        state.lineItems = [...action.payload.LineItems];
      })
      .addCase(fetchUserTransactions.rejected, (state, action) => {
        state.status.userTransactions = 'idle';
      })
      .addCase(fetchUserStats.pending, (state, action) => {
        state.status.userStats = 'loading';
      })
      .addCase(fetchUserStats.fulfilled, (state, action) => {
        state.status.userStats = 'idle';
        state.completedModules = action.payload.completedModules;
        state.completedLessons = action.payload.completedLessons
        state.journeys = action.payload.journeys;
      })
      .addCase(fetchUserStats.rejected, (state, action) => {
        state.status.userStats = 'idle';
      })
      .addCase(fetchUserJourneys.pending, (state, action) => {
        state.status.journeys = 'loading';
      })
      .addCase(fetchUserJourneys.fulfilled, (state, action) => {
        state.status.journeys = 'idle';
        state.journeys = action.payload;
      })
      .addCase(fetchUserJourneys.rejected, (state, action) => {
        state.status.journeys = 'idle';
      })
      .addCase(signUpUserToJourney.pending, (state, action) => {
        state.status.journeys = 'loading';
      })
      .addCase(signUpUserToJourney.fulfilled, (state, action) => {
        state.status.journeys = 'idle';
      })
      .addCase(signUpUserToJourney.rejected, (state, action) => {
        state.status.journeys = 'idle';
      })
      .addCase(fetchUserActivities.pending, (state, action) => {
        state.status.activities = 'loading';
      })
      .addCase(fetchUserActivities.fulfilled, (state, action) => {
        state.status.activities = 'idle';
        state.activities = action.payload;
      })
      .addCase(fetchUserActivities.rejected, (state, action) => {
        state.status.activities = 'idle';
      })
      .addCase(sendSats.pending, (state, action) => {
        state.status.satsbalance = 'loading';
      })
      .addCase(sendSats.fulfilled, (state, action) => {
        state.status.satsbalance = 'idle';
        if (Number.isInteger(action.meta.arg.amount)) {
          state.satsbalance -= action.meta.arg.amount;
        }
      })
      .addCase(sendSats.rejected, (state, action) => {
        state.status.satsbalance = 'idle';
      })
      .addCase(fetchUserEarnings.pending, (state, action) => {
        state.status.earnings = 'loading';
      })
      .addCase(fetchUserEarnings.fulfilled, (state, action) => {
        state.status.earnings = 'idle';
        state.earnings = action.payload;
      })
      .addCase(fetchUserEarnings.rejected, (state, action) => {
        state.status.earnings = 'idle';
      })
      .addCase(fetchUserFlows.pending, (state, action) => {
        state.status.flows = 'loading';
      })
      .addCase(fetchUserFlows.fulfilled, (state, action) => {
        state.status.flows = 'idle';
        state.flows = action.payload;
      })
      .addCase(fetchUserFlows.rejected, (state, action) => {
        state.status.flows = 'idle';
      })
      .addCase(fetchRunningBalance.pending, (state, action) => {
        state.status.runningBalance = 'loading';
      })
      .addCase(fetchRunningBalance.fulfilled, (state, action) => {
        state.status.runningBalance = 'idle';
        state.runningBalance = action.payload;
      })
      .addCase(fetchRunningBalance.rejected, (state, action) => {
        state.status.runningBalance = 'idle';
      })
      .addCase(fetchInvoiceForUser.pending, (state, action) => {
        state.status.invoice = 'loading';
      })
      .addCase(fetchInvoiceForUser.fulfilled, (state, action) => {
        state.status.invoice = 'idle';
        state.invoice = action.payload;
      })
      .addCase(fetchInvoiceForUser.rejected, (state, action) => {
        state.status.invoice = 'idle';
      })
      .addCase(fetchInvoiceStatus.pending, (state, action) => {
      })
      .addCase(fetchInvoiceStatus.fulfilled, (state, action) => {
        state.invoicePaid = action.payload;
      })
      .addCase(fetchInvoiceStatus.rejected, (state, action) => {
      })
      .addCase(uploadProfilePicture.pending, (state, action) => {
        state.status.profilePicture = 'loading';
      })
      .addCase(uploadProfilePicture.fulfilled, (state, action) => {
        state.status.profilePicture = 'idle';
        state.user = {
          ...state.user,
          profilePic: action.payload.profilePic,
        };
      })
      .addCase(uploadProfilePicture.rejected, (state, action) => {
        state.status.profilePicture = 'idle';
      })
});

export const { setUserFromFirebase, setUserAvatar, cleanLoggedUser, cleanInvoice } = userSlice.actions;

export default userSlice.reducer;